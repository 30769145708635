// all the business logic will go here in container
// the page will be passed with all the props needed inside

import { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import Page from "./Page";
import configs from "../../config.json";

import { ReactComponent as TotalValue } from "../../assets/images/totallocked.svg";
import { ReactComponent as TotalTrade } from "../../assets/images/totaltrading.svg";
import { ReactComponent as AccruedFee } from "../../assets/images/accruedfee.svg";

import {
  GET_BLOCK,
  TRADE_VOLUME,
  TRAILING_PROFIT,
  ACCRUED_FEE,
  GET_CHART_PRICE,
} from "../../io/subgraph";
import { useCommonStateContext } from "../../hooks/commonStateContext";
import Loader from "../../components/loader/Loader";
import { useWeb3ConnectContext } from "../../hooks/web3ConnectContext";
import { useMetaMask } from "metamask-react";
import {
  depositedGovernance,
  depositedPlatform, getCalcOutData,
  trancheSupply,
} from "../../io/kava";
import { timeConditions } from "../../components/static/TimeOptions";
import { ethers } from "ethers";

const liquidityData = [
  { title: "Senior Tranche", type: "senior", risk: "Low Risk", classname: "risk lowRisk" },
  { title: "Mezzanine Tranche", type: "mezzanine", risk: "Moderate Risk", classname: "risk moderateRisk" },
  { title: "Junior Tranche", type: "junior", risk: "High Risk", classname: "risk highRisk" },
];

const tradingData = {
  columnTitle: [
    { title: "Pair", className: "" },
    { title: "Last Price", className: "" },
    { title: "24h Change", className: "" },
    { title: "24h High", className: "" },
    { title: "24h Low", className: "" },
    { title: "24h Volume", className: "" },
    { title: "24h Volume (USD)", className: "" },
    { title: "", className: "" },
  ],
};

const Container = () => {
  const [tradingDetails, setTradingDetails] = useState([]);
  const [earnDetails, setEarnDetails] = useState();
  const [liquidityDetails, setLiquidityDetails] = useState([]);
  const [trancheAprDetails, setTrancheAprDetails] = useState();
  const [metricsDetails, setMetricsDetails] = useState();

  const { setLoading, loading, setCalcSwapOutDetails } = useCommonStateContext();
  const { accountBalance } = useWeb3ConnectContext();
  const { chainId, account } = useMetaMask();

  const [stats] = useLazyQuery(TRADE_VOLUME, { context: { clientName: "analytics" } });
  const [blocks] = useLazyQuery(GET_BLOCK, { context: { clientName: "analytics" } });
  const [tranchePrice] = useLazyQuery(TRAILING_PROFIT);
  const [accruedFees] = useLazyQuery(ACCRUED_FEE, { context: { clientName: "analytics" } });
  const [takeQuery] = useLazyQuery(GET_CHART_PRICE);

  const metricsData = [
    { title: "Total Value Locked", value: metricsDetails ? metricsDetails?.totalValueLocked : 0, img: <TotalValue /> },
    {
      title: "Total Trading Volume",
      value: metricsDetails ? metricsDetails?.totalTradingVolume : 0,
      img: <TotalTrade />,
    },
    { title: "Accrued Fees", value: metricsDetails ? metricsDetails?.accruedFeeValue : 0, img: <AccruedFee /> },
  ];

  const earnData = [
    { title: "LAMP Gov Staking", deposited: earnDetails?.totalLgo },
    { title: "LAMP Staking", deposited: earnDetails?.totalLvl },
  ];

  useEffect(() => {
    getAllDetails();
  }, [account, chainId, accountBalance]);

  //for get trading pairs , metricsDetails, apr details for liquidity tranches and earn details
  const getAllDetails = async () => {
    setLoading(true);

    try {
      const { data: feeData } = await accruedFees();
      let totalFee = 0;
      feeData.tranches.map((tranche) => {
        totalFee += Number(tranche.totalFeeValue);
      });
      const trancheProfits = {};

      for (let i = 0; i < liquidityData.length; i++) {
        const { data: profitData } = await tranchePrice({
          context: { clientName: "analytics" },
          variables: { tranche: configs.tranches[liquidityData[i].type] },
        });
        trancheProfits[liquidityData[i].type] = profitData.trancheStats;
      }

      const liqUpData = [];
      liquidityData.map((elem) => {
        let sum = 0;
        if (trancheProfits[elem.type].length > 0) {
          trancheProfits[elem.type].map((val) => {
            sum += Number(val.totalPnL);
          });
          let feeCount = Number(trancheProfits[elem.type][0].totalFeeValue) - Number(trancheProfits[elem.type][trancheProfits[elem.type].length - 1].totalFeeValue);
          sum += feeCount;
        }
        liqUpData.push({
          ...elem,
          profit: sum,
        });
      });
      setLiquidityDetails(liqUpData);

      const currentTime = Date.now() / 1000;
      let tokenPrices = {};
      for (let i = 0; i < configs.supportedTokens.length; i++) {
        if (configs.supportedTokens[i] !== configs.stableCoin) {
          const { data: tokenPriceData } = await takeQuery({
            variables: {
              start: Math.round(currentTime - timeConditions["24H"].timeGap),
              end: Math.round(currentTime),
              period: timeConditions["24H"].periodName,
              from: configs.tokens[configs.supportedTokens[i]],
              to: configs.tokens[configs.supportedTokens[i]],
            },
            context: { clientName: "analytics" },
          });
          let prices = [];
          tokenPriceData.tokenFrom.map((elem) => {
            prices.push(Number(ethers.utils.formatUnits(elem.value, 30 - configs.decimals[configs.supportedTokens[i]])));
          });
          tokenPrices[configs.supportedTokens[i]] = prices;
        }
      }

      let tokenPriceDetails = [];
      configs.supportedTokens.map((token) => {
        if (token !== configs.stableCoin) {
          tokenPriceDetails.push({
            token,
            change: tokenPrices[token][tokenPrices[token].length - 1] - tokenPrices[token][0],
            open: tokenPrices[token][0],
            high: Math.max(...tokenPrices[token]),
            low: Math.min(...tokenPrices[token]),
            close: tokenPrices[token][tokenPrices[token].length - 1],
          });
        }
      });


      const { data: blockData } = await blocks();
      const { data } = await stats({
        variables: {
          b24: Number(blockData._meta.block.number) - configs.blockCount,
        },
      });

      let totalTradingVolume = 0;
      tokenPriceDetails = tokenPriceDetails.map((priceDetails) => {

        const currentVolume = `trade${configs.tokens[priceDetails?.token].toLowerCase()}`;
        const pastVolume = `trade${configs.tokens[priceDetails?.token].toLowerCase()}yesterday`;

        if (data[currentVolume].length > 0) {
          totalTradingVolume += Number(data[currentVolume][0].volumeUsd) / configs.divideValue;
        }

        const volumeData = {
          volume: (Number(data[currentVolume].length > 0 ? data[currentVolume][0].volume : 0)
            - Number(data[pastVolume].length > 0 ? data[pastVolume][0].volume : 0))
            / configs.divideVolume,
          usdVolume: (Number(data[currentVolume].length > 0 ? data[currentVolume][0].volumeUsd : 0)
            - Number(data[pastVolume].length > 0 ? data[pastVolume][0].volumeUsd : 0))
            / configs.divideValue,
        };

        return {
          ...priceDetails,
          ...volumeData,
          price: priceDetails.close,
          changePercentage: (priceDetails.change / Number(priceDetails.close)) * 100,
        };
      });

      setTradingDetails(tokenPriceDetails);

      if (accountBalance !== undefined && account !== null && chainId === configs.CHAIN_ID) {

        const poolData = await getCalcOutData();
        setCalcSwapOutDetails(poolData);

        let tranchesData = liqUpData.map((elem) => {
          elem.liquidity = poolData.trancheValues[elem.type];
          return elem;
        });

        setLiquidityDetails(tranchesData);

        const totalValueLocked = await trancheSupply();
        setMetricsDetails({ totalValueLocked, totalTradingVolume, accruedFeeValue: totalFee / 1e30 });

        // const totalLgo = await depositedGovernance();
        // const totalLvl = await depositedPlatform();
        // setEarnDetails({ totalLgo, totalLvl });
      } else {
        setEarnDetails({ totalLgo: 0, totalLvl: 0 });
      }
    } catch (err) {
      console.log("dashboard err--", err);
    }

    setLoading(false);
  };

  return (
    <div className="swap container">
      <Loader loading={loading} />
      <Page
        tradingDetails={tradingDetails}
        metricsData={metricsData}
        tradingData={tradingData}
        liquidityDetails={liquidityDetails}
        earnData={earnData}
      />
    </div>
  );
};

export default Container;
