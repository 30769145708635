// Will mostly have only UI component imports and placement in this file.

import { Card, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useCommonStateContext } from "../../../hooks/commonStateContext";

import flash from "../../../assets/images/flash.svg";
import arrowSvg from "../../../assets/images/arrow-forward.svg";

import PlButton from "../../../components/buttons/Button";

const Liquidity = ({ liquidityDetails }) => {
  const navigate = useNavigate();
  const { setLoading } = useCommonStateContext();
  const handleNavLiquidity = (title) => {
    setLoading(true);
    navigate(`/liquidity/${title === "Senior Tranche"
      ? "senior"
      : title === "Mezzanine Tranche"
        ? "mezzanine"
        : "junior"
    }`);
  };
  return (
    <>
      <div className="trading-section">
        <p className="title">Liquidity</p>
        <Row xs={1} md={3} className="cards-deatils g-5">
          {liquidityDetails.map((item, id) => (
            <Col key={id}>
              <Card
                className="shadowed-box p-0 border-0 pointer-cursor"
                onClick={() => handleNavLiquidity(item.title)}
              >
                <Card.Body>
                  <div className="d-flex align-items-end justify-content-between w-100">
                    <div>
                      <div className="liquidity_data gap-2 mb-3">
                        <p className="liquidity_details bold mb-0">{item.title}</p>
                        <span className={item.classname}>
                          <img src={flash} height={12} width={8} /> {item.risk}
                        </span>
                      </div>
                      <p className="mb-2">Assets Under Management</p>
                      <p className="mb-2">7-day Trailing Profit</p>
                    </div>
                    <div>
                      <div className="d-flex justify-content-end mb-3 align-items-start ">
                        <PlButton
                          src={arrowSvg}
                          className="arrow-transparent liquidity_card_arrow mb-1 p-0 d-flex justify-content-start"
                          height={14}
                          width={14}
                        />
                      </div>
                      <p className="mb-2 bold d-flex justify-content-end">
                        ${item?.liquidity ?  Math.round(Number(item.liquidity)).toLocaleString() : "0"}
                      </p>
                      <p className="mb-2 bold d-flex justify-content-end">
                        ${item.profit > 0 && item.profit < 0.01
                        ? "<0.01" : parseFloat(Number(item.profit).toFixed(2))}
                      </p>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
};

export default Liquidity;
