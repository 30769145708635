// Will mostly have only UI component imports and placement in this file.

import { Card, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import arrowSvg from "../../../assets/images/arrow-forward.svg";
import flash from "../../../assets/images/flash.svg";
import { useCommonStateContext } from "../../../hooks/commonStateContext";

import PlButton from "../../../components/buttons/Button";
import { ReactComponent as InfoTag } from "../../../assets/images/info_outline.svg";
import { toolTipData } from "../../../components/static/ToolTipData";
import ReactTooltip from "react-tooltip";

const TradingTranche = ({ tranchesDetails }) => {
  const navigate = useNavigate();
  const { loading, setLoading } = useCommonStateContext();

  const handleNavigation = (tranche) => {
    setLoading(true);
    navigate(`/liquidity/${tranche}`);
  };
  // console.log('tranchesDetails-',tranchesDetails);
  return (
    <>
      <Row>
        <div className="trading-section">
          <p className="title mb-1 mt-0">Trading Pools</p>
          <p className="mb-3 pool_text">Deposit any asset to one or more tranches to start earning protocol revenues
            (from swaps and leveraged trading) as well as AIPX token incentives on top.</p>
          {tranchesDetails.map((item, id) => (
            <Col key={id} className="pools_column">
              <Card className="shadowed-box earn_card pointer-cursor"
                    onClick={() => handleNavigation(item.type)}>
                {/* onClick={() => navigate(`/liquidity/${item.type}`)}*/}
                <Card.Body className="liquidity-card-wrapper pe-0">
                  <div className="d-flex justify-content-between aligh-center">
                    <div className="liquidity-card-content">
                      <h2 className="d-flex align-items-center justify-content-start gap-2">
                        {item.title}{" "}
                        <span className={item.classname}>
                          <img src={flash} />
                          {item.risk}
                        </span>
                      </h2>
                      <p className="mb-0">Earn
                        <span
                          className="liquidity-value"> {item.dailyPoolLampReward !== undefined ? item.dailyPoolLampReward : "-"} AIPX
                        </span> per day
                      </p>
                    </div>
                    <div className="liquidity-card-content">
                      <p className="d-flex align-items-center gap-1">Price <InfoTag
                        className="info_icon tranche_info"
                        data-tip={item.type === "senior"
                          ? toolTipData.seniorTranchePrice : item.type === "junior"
                            ? toolTipData.juniorTranchePrice : toolTipData.mezzanineTranchePrice} />
                      </p>
                      <span>{item.price !== undefined ? `$${(item.price > 0 && item.price < 0.001) ? "<0.001" : parseFloat(item.price.toFixed(3))}` : "-"}</span>
                    </div>
                    <div className="liquidity-card-content">
                      <p className="d-flex align-items-center gap-1">Liquidity <InfoTag
                        className="info_icon tranche_info" data-tip={toolTipData.poolLiquidity} /></p>
                      <span>{item.liquidity !== undefined ? item.liquidity > 1
                        ? `$${Math.round(Number(item.liquidity)).toLocaleString()}` : (item.liquidity > 0 && item.liquidity < 0.01)
                          ? "<0.01" : Number(item.liquidity).toFixed(2) : "-"}</span>
                    </div>
                    <div className="liquidity-card-content">
                      <p className="d-flex align-items-center gap-1">7-day Trailing Profit</p>
                      <span>{item.profit !== undefined ? `$${(item.profit > 0 && item.profit < 0.01) ? "<0.01" : parseFloat(item.profit.toFixed(2))}` : "-"}</span>
                    </div>

                    <div className="liquidity-card-arrowIcon d-flex align-items-center">
                      <PlButton src={arrowSvg} className="arrow-transparent" height={14} width={14} />
                    </div>
                  </div>
                </Card.Body>
              </Card>
              <ReactTooltip backgroundColor="black" className="react_tooltip" arrowColor="black" place="top" type="dark"
                            effect="solid" />
            </Col>
          ))}
        </div>
      </Row>
    </>
  );
};

export default TradingTranche;
