import React from "react";
import loader from "../../assets/images/loader.gif";

const Loader = ({ loading }) => {
  var duration = 900,
    element,
    step,
    //frames = ["▰▱▱▱▱▱▱", "▰▰▱▱▱▱▱", "▰▰▰▱▱▱▱", "▰▰▰▰▱▱▱", "▰▰▰▰▰▱▱", "▰▰▰▰▰▰▱", "▰▰▰▰▰▰▰", "▰▱▱▱▱▱▱"];
    //frames = ["∙∙∙", "●∙∙", "∙●∙", "∙∙●", "∙∙∙"];
    //frames = ["d", "q", "p", "b"];
    frames = ["▹▹▹▹▹", "▸▹▹▹▹", "▹▸▹▹▹", "▹▹▸▹▹", "▹▹▹▸▹", "▹▹▹▹▸"];
  //frames = "▙▛▜▟".split("");
  //frames = '▤▧▥▨'.split('');
  //frames = '◴◵◶◷'.split('');
  //frames = '◩◪'.split('');
  //frames = '◰◱◲◳'.split('');
  //frames = '|/-\\'.split('');
  //frames = '◐◓◑◒'.split('');

  step = function (timestamp) {
    var frame = Math.floor((timestamp * frames.length) / duration) % frames.length;
    if (!element) {
      element = window.document.getElementById("spinner");
    }
    element.innerHTML = frames[frame];
    return window.requestAnimationFrame(step);
  };

  window.requestAnimationFrame(step);

  return (
    <>
      <div className={`loading_div ${loading ? "loading_div_display" : undefined}`}>
        <div id="spinner" className="loading">
          LOADING
        </div>
        {/*<img className="loading_img" src={loader} alt="loading.." />*/}
      </div>
    </>
  );
};

export default Loader;
