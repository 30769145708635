import Modal from "react-bootstrap/Modal";

import closeIcon from "../../assets/images/modal-close-icon.svg";
import arrow from "../../assets/images/launch_redirect.svg";
import confirm from "../../assets/images/confirm_tickmark.svg";
import { useCommonStateContext } from "../../hooks/commonStateContext";

const CompleteTransaction = ({
  show,
  onHide,
  selectedOrder,
  swapConditions,
  trancheConditions,
  tradeConditions,
  amountOut,
  amountIn,
  trancheType,
  successMessage,
  orderMessage,
}) => {
  const { explorerURL, usdValue } = useCommonStateContext();
  // console.log("tradeConditions-", tradeConditions);
  let addRemoveLiquidityMessage;
  if(trancheType){
    let isBuy = trancheConditions.selectedTrancheTab === 'buy';
    let trancheName = trancheType === "senior" ? "Senior" 
      : trancheType === "mezzanine" ? "Mezzanine" : "Junior";
    addRemoveLiquidityMessage = isBuy 
      ? ["Bought ", `${parseFloat(Number(amountOut).toFixed(5))}`,` ${trancheName} ALP`, 
        `${parseFloat(Number(amountIn).toFixed(5))}`, ` ${trancheConditions.selectedToken?.value}`]
      : ["Sold ", `${parseFloat(Number(amountIn).toFixed(5))}`, ` ${trancheName} ALP`, 
        `${parseFloat(Number(amountOut).toFixed(5))}`, ` ${trancheConditions.selectedToken?.value}`]
  }
  return (
    <Modal size="lg" centered dialogClassName="aipx-modal success-modal" show={show}>
      <Modal.Body>
        <div className="close-icon" onClick={onHide}>
          <img src={closeIcon} height={12} width={12} />
        </div>

        <div className="d-flex mb-4 align-items-center justify-content-center">
          <img src={confirm} />
        </div>
        <div className="confirm_modal mt-3 d-flex align-items-center justify-content-center flex-column gap-2">
          {trancheType ? (
            <p className="modal_text">
              {addRemoveLiquidityMessage[0]}
              <span>
                {addRemoveLiquidityMessage[1]}
                {addRemoveLiquidityMessage[2]}
              </span>
              {" "}for{" "}
              <span>
                {addRemoveLiquidityMessage[3]}
                {addRemoveLiquidityMessage[4]}
              </span>
            </p>
          ) : selectedOrder ? (
            <p className="modal_text">
              Canceled order to swap{" "}
              <span>
                {selectedOrder.priceIn} {selectedOrder.assetIn}{" "}
              </span>{" "}
              for{" "}
              <span>
                {parseFloat(Number(selectedOrder.priceOut).toFixed(5))} {selectedOrder?.assetOut}
              </span>
            </p>
          ) : swapConditions ? (
            <p className="modal_text">
              {swapConditions.selectedSwapTab === "market" ? "Swapped" : "Order created to swap"}{" "}
              <span>
                {parseFloat(Number(amountIn).toFixed(5))} {swapConditions.selectedFrom?.value}{" "}
              </span>{" "}
              for{" "}
              <span>
                {parseFloat(Number(amountOut).toFixed(5))} {swapConditions.selectedTo?.value}
              </span>
            </p>
          ) : orderMessage?.length > 0 ? (
            <p className="modal_text">{orderMessage} </p>
          ) : tradeConditions ? (
            <p className="modal_text">
              Requested to increase {tradeConditions.selectedTo.value} {tradeConditions.selectedTradeTab.toUpperCase()}{" "}
              position by <span>${usdValue.toUsdValue}</span>
            </p>
          ) : successMessage?.length > 0 ? (
            <h3 className="modal_text">{successMessage} </h3>
          ) : null}
          <a className="pointer-cursor modal_link" href={explorerURL} target="_blank">
            View Txn Hash
          </a>
        </div>

        {/*<div className="confirm_modal mt-3">*/}
        {/*  <p className="modal_text d-flex align-items-center justify-content-center gap-2">*/}
        {/*    Transaction Completed!{" "}*/}
        {/*    <img className="pointer-cursor" onClick={() => window.open(explorerURL, "_blank")} src={arrow} />*/}
        {/*  </p>*/}
        {/*</div>*/}
      </Modal.Body>
    </Modal>
  );
};

export default CompleteTransaction;
