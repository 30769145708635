import React, { useState } from "react";
import { tokenImages } from "../static/SupportedTokens";
import WithTag from "../inputFields/WithTag";
import arrow from "../../assets/images/launch_redirect.svg";
import CustomPagination from "../CustomPagination";

const historyColumnTitles = ["Time", "Index", "Action", ""];

const HistoryTab = ({ tableData }) => {
  const [currentPage, setCurrentPage] = useState(0);
  let itemsPerPage = 10;
  const pageCount = Math.ceil(tableData?.allHistory?.length / itemsPerPage);
  const startIndex = currentPage * itemsPerPage;
  const currentItems = tableData?.allHistory?.slice(startIndex, startIndex + itemsPerPage);

  const handlePageClick = (selectedPage) => {
    if (selectedPage >= 0 && selectedPage < pageCount) {
      setCurrentPage(selectedPage);
    }
  };

  return (
    <>
      <div className="trading-section history_table_section">
        {tableData?.allHistory?.length === 0 ? (
          <div className="d-flex align-items-center justify-content-center empty_div w-100">
            <span>No Record.</span>
          </div>
        ) : (
          <table className="w-100">
            <thead>
            <tr className="heading swap-content-wrapper">
              {historyColumnTitles.map((column, i) => (
                <th key={i}>{column}</th>
              ))}
            </tr>
            </thead>
            <tbody className="swap-order-wrapper trade-order-wrapper">
            {currentItems?.map((order, i) => (
              <tr className="swap-data data mb-0" key={i}>
                <td className="ps-0 opacity-75">
                  <span>{order.dateTime}</span>
                </td>
                <td className="ps-0">
                  <div className="d-flex align-items-center gap-2">
                    {/*<img src={tokenImages[order.indexAsset].image} height={20} width={20} />*/}
                    <WithTag
                      isInput={false}
                      tag={order.side === 0 ? "long" : "short"}
                      className={order.side === 0 ? "long" : "short"}
                    />
                    <span className="fw-600">{order.indexAsset}/USD</span>

                  </div>
                </td>
                <td className="pe-0">
                            <span>
                              {order.status === "LIQUIDATED"
                                ? `${order.indexAsset} ${order.side === 0 ? "LONG" : "SHORT"} of $${parseFloat(Number(order.sizeChangeValue).toFixed(3))} has been Liquidated`
                                : order.status === "OPEN"
                                  ? order.sizeChangeValue > 0
                                    ? `Requested to ${order.updateType === "INCREASE" ? "Increase" : "Decrease"} ${
                                      order.indexAsset
                                    } ${order.side === 0 ? "LONG" : "SHORT"} by $${parseFloat(
                                      Number(order.sizeChangeValue).toFixed(3),
                                    )}`
                                    : order.sizeChangeValue == 0 && order.updateType === "INCREASE"
                                      ? `Requested to deposit $${parseFloat(Number(order.collateral).toFixed(2))} to ${order.indexAsset} ${order.side === 0 ? "LONG" : "SHORT"}`
                                      : order.sizeChangeValue == 0 && order.updateType === "DECREASE"
                                        ? `Requested to withdraw $${parseFloat(Number(order.collateral).toFixed(2))} from ${order.indexAsset} ${
                                          order.side === 0 ? "LONG" : "SHORT"}`
                                        : "Requested to close"
                                  : order.status === "FILLED"
                                    ? order.sizeChangeValue > 0
                                      ? `${order.indexAsset} ${order.side === 0 ? "LONG" : "SHORT"} ${
                                        order.updateType === "INCREASE" ? "Increased" : "Decreased"
                                      } by $${parseFloat(Number(order.sizeChangeValue).toFixed(3))}`
                                      : order.sizeChangeValue == 0 && order.updateType === "INCREASE"
                                        ? `Deposited $${parseFloat(Number(order.collateral).toFixed(2))} to ${order.indexAsset} ${order.side === 0 ? "LONG" : "SHORT"}`
                                        : order.sizeChangeValue == 0 && order.updateType === "DECREASE"
                                          ? `Withdrew $${parseFloat(Number(order.collateral).toFixed(2))} from ${order.indexAsset} ${order.side === 0 ? "LONG" : "SHORT"}`
                                          : "closed"
                                    : order.status === "CANCELLED"
                                      ? order.sizeChangeValue > 0
                                        ? `Cancelled ${order.updateType === "INCREASE" ? "Increase" : "Decrease"} ${
                                          order.indexAsset
                                        } ${order.side === 0 ? "LONG" : "SHORT"} by $${parseFloat(
                                          Number(order.sizeChangeValue).toFixed(3),
                                        )}`
                                        : order.sizeChangeValue == 0 && order.updateType === "INCREASE"
                                          ? `Cancelled deposit $${parseFloat(Number(order.collateral).toFixed(2))} to ${order.indexAsset} ${order.side === 0 ? "LONG" : "SHORT"}`
                                          : order.sizeChangeValue == 0 && order.updateType === "DECREASE"
                                            ? `Cancelled withdraw $${parseFloat(Number(order.collateral).toFixed(2))} from ${order.indexAsset} ${order.side === 0 ? "LONG" : "SHORT"}`
                                            : "Cancelled"
                                      : "Expired"}{" "}
                            </span>
                </td>
                <td>
                  <a target="_blank" href={`https://coston2-explorer.flare.network/tx/${order.tx}`}>
                    <img src={arrow} height={12} width={12} className="pointer-cursor" />
                  </a>
                </td>
              </tr>
            ))}
            </tbody>
          </table>
        )}
        {tableData.allHistory?.length > itemsPerPage &&
        <div className="pagination">
          <CustomPagination pageCount={pageCount} onPageChange={handlePageClick} currentPage={currentPage} />
        </div>
        }
      </div>
    </>
  );
};

export default HistoryTab;
