// Will mostly have only UI component imports and placement in this file.

import { useNavigate, useParams } from "react-router-dom";
import { useStateContext } from "../../hooks/commonStateContext";
import { Row } from "react-bootstrap";
import { useCommonStateContext } from "../../hooks/commonStateContext";

import icon from "../../assets/images/liquidityIcon.svg";
import flash from "../../assets/images/flash.svg";
import backArrow from "../../assets/images/arrow_back.svg";

import Metrics from "./cards/Metrics";
import Status from "./cards/Status";
import AnalysisChart from "./cards/AnalysisChart";
import BuySell from "./cards/BuySell";

const Page = ({
  trancheMetricsData,
  trancheStatusData,
  tradingTranchesData,
  trancheType,
  trancheConditions,
  setTrancheConditions,
  isSwitchOn,
  setIsSwitchOn,
  setUserInfo,
  setPoolInfo,
  fetchTrancheConditions,
}) => {
  const navigate = useNavigate();
  const { name } = useParams();
  const { setLoading } = useCommonStateContext();
  const handleNavBack = () => {
    setLoading(true);
    navigate("/liquidity");
  }

  return (
    <>
      {/*<h1 className="page_title dashboard_title d-flex align-items-center justify-content-start mb-0">*/}
      {/*  <img src={icon} alt="" /> LIQUIDITY*/}
      {/*</h1>*/}
      <p
        className="back_btn pointer-cursor d-flex align-items-center gap-1 w-fit-content"
        onClick={() => handleNavBack()}
      >
        <img src={backArrow} width={18} height={10} /> Back
      </p>

      <div className="d-flex justify-content-between align-items-center liquidity-details-wrapper mb-4">
        {tradingTranchesData.map(
          (item) =>
            item.type === name && (
              <div className="d-flex align-items-center gap-3" key={item.title}>
                <span className="liquidity-title">
                  <h3>{item.title}</h3>
                </span>
                <span className={item.classname}>
                  <img src={flash} height={10} width={8} /> {item.risk}
                </span>
              </div>
            )
        )}

        <div className="liquidity-details-perdays d-flex align-items-center gap-4">
          <p className="reward_distribute mb-0">
            1 {trancheType === "senior" ? "Senior" : trancheType === "junior" ? "Junior" : "Mezzanine"} ALP ={" "}
            <span>
              {trancheConditions?.tranchePrice ? `$${parseFloat(trancheConditions?.tranchePrice?.toFixed(3))}` : "-"}
            </span>
          </p>
          <p className="reward_distribute mb-0">
            Earn <span>{trancheConditions.dailyLampRewards ? trancheConditions.dailyLampRewards : "-"} AIPX</span> per
            day
          </p>
        </div>
      </div>
      <Metrics
        trancheMetricsData={trancheMetricsData}
        trancheType={trancheType}
        trancheConditions={trancheConditions}
      />
      <Row xs={1} className="g-5 mt-5 align-items-start justify-content-center">
        <Status trancheStatusData={trancheStatusData} trancheConditions={trancheConditions} />
        <BuySell
          trancheType={trancheType}
          trancheConditions={trancheConditions}
          setTrancheConditions={setTrancheConditions}
          isSwitchOn={isSwitchOn}
          setIsSwitchOn={setIsSwitchOn}
          setUserInfo={setUserInfo}
          setPoolInfo={setPoolInfo}
          fetchTrancheConditions={fetchTrancheConditions}
        />
      </Row>
      {/*<AnalysisChart trancheType={trancheType} />*/}
    </>
  );
};

export default Page;
