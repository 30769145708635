import React, { useState } from "react";
import { useCommonStateContext } from "../../hooks/commonStateContext";

import { tokenImages } from "../static/SupportedTokens";
import WithTag from "../inputFields/WithTag";

import CancelOrder from "../modals/tradeModals/CancelOrder";
import { cancelTradeOrder } from "../../io/kava";

import { waitingToast } from "../toasts/Waiting";
import { successToast } from "../toasts/Success";
import { handleError } from "../toasts/Error";
import CustomPagination from "../CustomPagination";


const orderColumnTitles = ["Order Type", "Type", "Order", "Trigger Condition", "Market Price", "Action"];

const OrdersTab = ({ tableData, setOrderMessage, setCompleteTransactionModal }) => {

  //to manage data for cancel modal
  const [showCancelOrderModal, setShowCancelOrderModal] = useState(false);
  const [cancelOrder, setCancelOrder] = useState();

  const { setLoading, setExplorerURL } = useCommonStateContext();

  const [currentPage, setCurrentPage] = useState(0);

  const handleShowCancelModal = (order) => {
    console.log("order--", order);
    setCancelOrder(order);
    setShowCancelOrderModal(true);
  };

  const handleCancelOrder = async (order) => {
    setLoading(true);

    try {
      // waitingToast(
      //   `Cancelling order to ${order.updateType} ${order.indexAsset} ${order.side === 0 ? "Long" : "Short"}`,
      // );

      const res = await cancelTradeOrder(order);

      successToast(`Cancelled order to ${order.updateType} ${order.indexAsset} ${order.side === 0 ? "Long" : "Short"}`);
      setOrderMessage(
        `Cancelled order to ${order.updateType} ${order.indexAsset} ${order.side === 0 ? "Long" : "Short"}`,
      );

      //This URL needs to be updated according to the Kava Explorer.
      setExplorerURL(`https://coston2-explorer.flare.network/tx/${res.hash}`);
      setCompleteTransactionModal(true);
    } catch (e) {
      handleError(e);
    }
    setLoading(false);
  };

  let itemsPerPage = 10;
  const pageCount = Math.ceil(tableData?.allOrders?.length / itemsPerPage);
  const startIndex = currentPage * itemsPerPage;
  const currentItems = tableData?.allOrders?.slice(startIndex, startIndex + itemsPerPage);

  const handlePageClick = (selectedPage) => {
    if (selectedPage >= 0 && selectedPage < pageCount) {
      setCurrentPage(selectedPage);
    }
  };

  return (
    <>
      <div className="trading-section history_table_section">
        {tableData?.allOrders?.length === 0 ? (
          <div className="d-flex align-items-center justify-content-center empty_div w-100">
            <span>You haven't placed any orders yet.</span>
          </div>
        ) : (
          <table className="w-100">
            <thead>
            <tr className="heading swap-content-wrapper">
              {orderColumnTitles.map((column, i) => (
                <th key={i}>{column}</th>
              ))}
            </tr>
            </thead>
            <tbody className="swap-order-wrapper trade-order-wrapper">
            {currentItems?.map((order, i) => (
              <tr className="swap-data data mb-0" key={i}>
                <td>
                  <div className="d-flex align-items-center gap-1">
                    <img src={tokenImages[order.indexAsset].image} height={20} width={20} />
                    <span className="fw-600">{order.indexAsset}/USD</span>
                    <WithTag
                      isInput={false}
                      tag={order.side === 0 ? "long" : "short"}
                      className={order.side === 0 ? "long" : "short"}
                    />
                  </div>
                </td>
                <td>
                  <span>{order.type}</span>
                </td>
                <td>
                    <span>
                      {order.sizeChangeValue > 0
                        ? `Requested to ${order.updateType === "INCREASE" ? "Increase" : "Decrease"} ${
                          order.indexAsset
                        } ${order.side === 0 ? "LONG" : "SHORT"} by $${parseFloat(Number(order.sizeChangeValue).toFixed(3))}`
                        : order.sizeChangeValue == 0 && order.updateType === "INCREASE"
                          ? `Requested to deposit $${parseFloat(Number(order.collateral).toFixed(2))} to ${order.indexAsset} ${order.side === 0 ? "LONG" : "SHORT"}`
                          : order.sizeChangeValue == 0 && order.updateType === "DECREASE"
                            ? `Requested to withdraw $${parseFloat(Number(order.collateral).toFixed(2))} from ${order.indexAsset} ${
                              order.side === 0 ? "LONG" : "SHORT"
                            }`
                            : `Requested to close ${order.indexAsset} ${
                              order.side === 0 ? "LONG" : "SHORT"
                            }`
                      }
                    </span>
                </td>
                <td>
                    <span>
                      Market Price{" "}
                      {order.triggerAboveThreshold ? "≥" : "≤"}{" "}
                      ${parseFloat(Number(order.priceTrigger).toFixed(3))}{" "}
                    </span>
                </td>
                <td>
                  <span>${parseFloat(Number(order.marketPrice).toFixed(3))}</span>
                </td>
                <td>
                    <span onClick={() => handleShowCancelModal(order)} className="cancel_text">
                      Cancel
                    </span>
                </td>
              </tr>
            ))}
            </tbody>
          </table>
        )}
        {tableData.allOrders?.length > itemsPerPage &&
        <div className="pagination">
          <CustomPagination pageCount={pageCount} onPageChange={handlePageClick} currentPage={currentPage} />
        </div>
        }
      </div>

      {showCancelOrderModal && (
        <CancelOrder
          handleCancelOrder={handleCancelOrder}
          cancelOrder={cancelOrder}
          show={showCancelOrderModal}
          onHide={() => setShowCancelOrderModal(false)}
        />
      )}

    </>
  );
};

export default OrdersTab;