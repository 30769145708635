import { toast } from "react-toastify";
import { toastOptions } from "./ToastOptions";

export const switchNetworkToast = (chainID, isSwitch) => {
  toast(`${isSwitch ? "Switch to" : "Add"} Coston2 Testnet`, {
    ...toastOptions,
    toastId: chainID,
    autoClose: false,
    style: { backgroundColor: "#252927", color: "#E6BB00", textAlign: "center" },
  });
};