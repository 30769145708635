import { Nav, Navbar } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { useMetaMask } from "metamask-react";

import logo from "../assets/images/aipx-logo.svg";
import person from "../assets/images/person.svg";
import wallet from "../assets/images/wallet.svg";
import loaderSvg from "../assets/images/icons8-refresh.svg";

import { useWeb3ConnectContext } from "../hooks/web3ConnectContext";
import config from "../config.json";
import { useCommonStateContext } from "../hooks/commonStateContext";

import React from "react";

const Header = () => {
  const { status, connect, chainId, account } = useMetaMask();
  const { userData, syncStatus } = useCommonStateContext();
  const { accountBalance, isChainAvailable, handleAddNetwork, handleKavaChainChange } = useWeb3ConnectContext();

  const walletConnect = () => {
    connect();
  };

  return (
    <div className="header container">
      <Navbar bg="dark" expand="lg" className={"header-main"}>
        <NavLink className={"header-logo"} to={"/"}>
          <img src={logo} alt="" />
        </NavLink>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav className="me-auto my-2 my-lg-0" style={{ maxHeight: "100px" }} navbarScroll>
            <NavLink to={`/dashboard`}>Dashboard</NavLink>
            <NavLink to={`/trade`}>Trade</NavLink>
            <NavLink to={`/swap`}>Swap</NavLink>
            {/*<NavLink to={`/earn`}>Earn</NavLink>*/}
            <NavLink to={`/liquidity`}>Liquidity</NavLink>
            {/*<NavLink to={`/auction`}>Auction</NavLink>*/}
            {/*<NavLink to={`/dao`}>DAO</NavLink>*/}
            {/*<NavLink to={`/analytics`}>Analytics</NavLink>*/}
            {/*<NavLink to={`/leaderboard`}>Leaderboard</NavLink>*/}
          </Nav>

          {status === "connected" ? (
            <div className="d-flex align-items-center gap-3">
              {chainId === config.CHAIN_ID &&
              <span className={`w-data sync_message ${syncStatus}`}>
                <img
                  className={`${syncStatus === "syncing" ? "rotate" : "static"}`}
                  src={loaderSvg}
                  height={16}
                  width={16}
                />
                {syncStatus}
              </span>
              }

              <div
                className={`d-flex align-items-center justify-content-around w-chain w-auto 
              ${chainId !== config.CHAIN_ID ? "pointer-cursor chain_btn" : undefined}`}
                onClick={() => chainId !== config.CHAIN_ID ? isChainAvailable ? handleKavaChainChange(chainId) : handleAddNetwork() : null}
              >
                <span>{chainId === config.CHAIN_ID ? "COSTON2" : isChainAvailable ? "SWITCH" : "ADD COSTON2"}</span>
              </div>
              {chainId === config.CHAIN_ID &&
              <>
                   <span className="w-data">
                <div className="head_lamp"></div>
                     {userData ? parseFloat(Number(userData.platFormBalance).toFixed(4)) : "0.0"} AIPX
              </span>
                <span className="w-data">
                <img src={wallet} height={14} width={20} />
                  {parseFloat(Number(userData ? userData.balances[config.nativeToken] : accountBalance).toFixed(4))}{" "}
                  {config.nativeToken}
              </span>
              </>
              }
              {/*<NavLink to={`/profile`}>*/}
              <span className="w-data">
                <img src={person} height={14} width={14} />
                {`${account?.slice(0, 5)}...${account?.slice(-5)}`}
              </span>
              {/*</NavLink>*/}
            </div>
          ) : status === "unavailable" ? (
            <div className="d-flex align-items-center justify-content-around w-connect w-auto">
              <span>Install Metamask</span>
            </div>
          ) : status === "connecting" || status === "initializing" ? (
            <div className="d-flex align-items-center justify-content-around w-connect w-auto pointer-cursor">
              <span>Initializing...</span>
            </div>
          ) : status === "notConnected" ? (
            <div
              className="d-flex align-items-center justify-content-around w-connect w-auto pointer-cursor"
              onClick={walletConnect}
            >
              <span>Connect</span>
            </div>
          ) : null}
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default Header;
