// all the business logic will go here in container
// the page will be passed with all the props needed inside

import Page from "./Page";
import { ReactComponent as TotalValue } from "../../assets/images/totallocked.svg";
import { ReactComponent as TreasuryReserve } from "../../assets/images/treasuryReserve.svg";
import { ReactComponent as EarnAssets } from "../../assets/images/earnIcon.svg";
import { ReactComponent as LampSymbol } from "./assets/lampSymbol.svg";
import { ReactComponent as TotalPercentage } from "../../assets/images/totalPercentag.svg";
import { useEffect, useState } from "react";
import {
  getApprovedLgo,
  getApprovedLvl,
  getLgoBalance,
  getLvlBalance,
  getPendingRewards,
  getStakedBalance,
  lgoApprove,
  lvlApprove,
} from "../../io/kava";
import { useMetaMask } from "metamask-react";
import { useCommonStateContext } from "../../hooks/commonStateContext";
import Loader from "../../components/loader/Loader";
import configs from "../../config.json";
import { waitingToast } from "../../components/toasts/Waiting";
import { successToast } from "../../components/toasts/Success";
import { handleError } from "../../components/toasts/Error";
import { useWeb3ConnectContext } from "../../hooks/web3ConnectContext";

const Container = () => {
  const { account, chainId } = useMetaMask();
  const { loading, setLoading } = useCommonStateContext();
  const { accountBalance } = useWeb3ConnectContext();
  const [balances, setBalances] = useState({ toStake: 0, staked: 0, claimable: 0, redeem: 0 });
  const [approvedBalance, setApprovedBalance] = useState({ lvl: 0, lgo: 0 });

  // for overview tab
  const [treasuryDetails, setTreasuryDetails] = useState();
  const [stakeDetails, setStakeDetails] = useState();
  // const [amounts, setAmounts] = useState({ stake: "", unstake: "", claim: "" });

  const [stakeFee, setStakeFee] = useState();

  //for redemption tab
  const [estimatedDetails, setEstimatedDetails] = useState(); // Estimated LAMP Supply and Liquid Assets

  //for proposal tab
  const [votingPowerBalance, setVotingPowerBalance] = useState();
  const [proposalDetails, setProposalDetails] = useState();
  const [proposalType, setProposalType] = useState("All");

  //data fetch on first render
  useEffect(() => {
    if (account !== null) {
      fetchDaoDetails();
    }
  }, [ account, chainId]);

  //get proposals data  according to selected proposalType
  useEffect(() => {
    setProposalDetails();
  }, [proposalType]);

  const fetchDaoDetails = async () => {
    setLoading(true);

    const lvlBal = await getLvlBalance(account);
    console.log("lvlBal--", lvlBal);
    const approvedLvl = await getApprovedLvl(account, configs.earn.platformStake);
    console.log("approvedLvl--", approvedLvl);

    const lgoBal = await getLgoBalance(account);
    console.log("lgoBal-", lgoBal);
    const approvedLgo = await getApprovedLgo(account, configs.dao.governanceRedemptionPool);
    console.log("approvedLgo--", approvedLgo);

    setApprovedBalance({ lvl: Number(approvedLvl), lgo: Number(approvedLgo) });
    const stakedBalance = await getStakedBalance(account);
    console.log("stakedBalance-", stakedBalance);
    const claimBalance = await getPendingRewards(account);
    console.log("claimBalance--", claimBalance);
    setBalances({
      toStake: Number(lvlBal),
      staked: Number(stakedBalance),
      claimable: Number(claimBalance),
      redeem: Number(lgoBal),
    });
    setLoading(false);
  };

  const handleLvlApprove = async () => {
    setLoading(true);
    try {
      // waitingToast(`Approving Lamp`);

      await lvlApprove(configs.earn.platformStake);

      successToast(`Lamp Approved!`);
      await fetchDaoDetails();
    } catch (e) {
      handleError(e);
      setLoading(false);
    }
  };

  const handleLgoApprove = async () => {
    setLoading(true);
    try {
      // waitingToast(`Approving LGO`);

      await lgoApprove(configs.dao.governanceRedemptionPool);

      successToast(`LGO Approved!`);
      await fetchDaoDetails();
    } catch (e) {
      handleError(e);
      setLoading(false);
    }
  };

  const metricsData = [
    { title: "Treasury Total Value", value: "$5,919438", img: <TotalValue /> },
    { title: "Treasury Reserve", value: "$2,621,985", details: "524,397 LAMP", img: <TreasuryReserve /> },
    { title: "Treasury Liquid Assets", value: "$3,297,453", details: "659,490 LAMP/USDT", img: <EarnAssets /> },
  ];

  const stakeData = [
    { title: "Total LAMP Locked", value: "7,524,397", details: "$37,621,985", img: <LampSymbol /> },
    { title: "Staking APR", value: "205.36%", details: "1.25 LAMP per day", img: <TotalPercentage /> },
  ];

  return (
    <div className="swap container">
      <Loader loading={loading} />
      <Page
        metricsData={metricsData}
        stakeData={stakeData}
        balances={balances}
        approvedBalance={approvedBalance}
        account={account}
        handleLvlApprove={handleLvlApprove}
        handleLgoApprove={handleLgoApprove}
      />
    </div>
  );
};

export default Container;
