import { toast } from "react-toastify";
import completeImg from "../../assets/images/completed-icon.svg";
import { toastOptions } from "./ToastOptions";
import React from "react";

export const successToast = (message) => {
  toast.dismiss("loading");
  toast(
    <div className="d-flex align-items-center justify-content-center gap-2 flex-column">
      <p className="toast_heading">
        <img src={completeImg} alt="success" /> Transaction successful
      </p>
      <p className="toast_message">{message}</p>
    </div>,
    toastOptions
  );
};
