import { Card, Col, Row } from "react-bootstrap";

import kava from "../../../assets/images/flare_coin_logo.png";
import { ReactComponent as RotationIcon } from "../../../assets/images/rotation-icon.svg";

const Metrics = ({ metricsData }) => {
  return (
    <>
      <div className="d-flex justify-content-between align-items-center liquidity-details-wrapper mb-4">
        <div className="d-flex">
          <div className="liquidity-first-round"></div>
          <img className="trade_head_img" height={30} width={30} src={kava} />
          <span className="liquidity-title ms-2">
            <h3>AIPX/FLR</h3>
          </span>
          <span className="ms-4 mt-2">
            <p className="liquidity-value liquidity-details-title">
              1 LAMP = 500 FLR
              <RotationIcon />
            </p>
          </span>
        </div>
        <div className="liquidity-details-perdays">
          <p>
            Earn <span className="liquidity-value ">100 LAMP</span> per day
          </p>
        </div>
      </div>
      <Row xs={1} md={4} className="cards-deatils liquidity-details-cards g-5">
        {metricsData.map((item, id) => (
          <Col key={id}>
            <Card className="shadowed-box p-0">
              <Card.Body>
                <div>
                  <p className="mb-2">{item.title}</p>
                  <span>{item.value}</span>
                </div>
                {item.img}
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default Metrics;
