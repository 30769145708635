import tether from "../../assets/images/usdt-coin.png";
import kava from "../../assets/images/flare_coin_logo.png";
import eth from "../../assets/images/ethereum-coin.svg";
import bitcoin from "../../assets/images/bitcoin.png";
import cake from "../../assets/images/failed-icon.svg";

export const supportedTokens = (balances) => {
  return (
    [
      {
        label: (
          <div className="d-flex align-items-center justify-content-between main_label">
            <div className="text_label d-flex align-items-center gap-1">
              <img src={tether} height={16} width={16} />
              <p className="mb-0">USDT</p>
            </div>
            <span className="mb-0">{balances?.USDT ? parseFloat(Number(balances.USDT).toFixed(5)) : "-"}</span>
          </div>
        ),
        value: "USDT",
      },
      {
        label: (
          <div className="d-flex align-items-center justify-content-between main_label">
            <div className="text_label d-flex align-items-center gap-1">
              <img src={kava} height={16} width={16} />
              <p className="mb-0">FLR</p>
            </div>
            <span className="mb-0">{balances?.FLR ? parseFloat(Number(balances.FLR).toFixed(5)) : "-"}</span>
          </div>
        ),
        value: "FLR",
      },
      {
        label: (
          <div className="d-flex align-items-center justify-content-between main_label">
            <div className="text_label d-flex align-items-center gap-1">
              <img src={eth} height={16} width={16} />
              <p className="mb-0">ETH</p>
            </div>
              <span className="mb-0">{balances?.ETH ? parseFloat(Number(balances.ETH).toFixed(5)) : "-"}</span>
          </div>
        ),
        value: "ETH",
      },
      {
        label: (
          <div className="d-flex align-items-center justify-content-between main_label">
            <div className="text_label d-flex align-items-center gap-1">
              <img src={bitcoin} height={16} width={16} />
              <p className="mb-0">BTC</p>
            </div>
            <span className="mb-0">{balances?.BTC ? parseFloat(Number(balances.BTC).toFixed(5)) : "-"}</span>
          </div>
        ),
        value: "BTC",
      },
    ]
  );
};

export const posSizeTokens = [
  {
    label: (
      <div className="d-flex align-items-center gap-1">
        <img src={kava} height={20} width={20} /> FLR/USD
      </div>
    ),
    value: "FLR",
  },
  {
    label: (
      <div className="d-flex align-items-center gap-1">
        <img src={eth} height={20} width={20} /> ETH/USD
      </div>
    ),
    value: "ETH",
  },
  {
    label: (
      <div className="d-flex align-items-center gap-1">
        <img src={bitcoin} height={20} width={20} /> BTC/USD
      </div>
    ),
    value: "BTC",
  },
];

export const TradeChartTokens = [
  {
    label: (
      <div className="d-flex align-items-center gap-1">
        <img src={bitcoin} height={20} width={20} />
        BTC/USD
      </div>
    ),
    value: "BTC",
  },
  {
    label: (
      <div className="d-flex align-items-center gap-1">
        <img src={eth} height={20} width={20} /> ETH/USD
      </div>
    ),
    value: "ETH",
  },
  {
    label: (
      <div className="d-flex align-items-center gap-1">
        <img src={kava} height={20} width={20} />
        FLR/USD
      </div>
    ),
    value: "FLR",
  },
];

export const tokenImages = {
  USDT: { image: tether },
  BTC: { image: bitcoin },
  ETH: { image: eth },
  FLR: { image: kava },
  CAKE: { image: cake },
};
