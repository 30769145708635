// all the business logic will go here in container
// the page will be passed with all the props needed inside

import { useEffect, useState } from "react";
import { useMetaMask } from "metamask-react";
import Page from "./Page";
import Loader from "../../components/loader/Loader";
import { useParams } from "react-router-dom";
import { useCommonStateContext } from "../../hooks/commonStateContext";
import configs from "../../config.json";

import { ReactComponent as TotalPercentag } from "../../assets/images/totalPercentag.svg";
import { ReactComponent as TotalDolarIcon } from "../../assets/images/totaldolarIcon.svg";
import { ReactComponent as WalletIcon } from "../../assets/images/walletIcon.svg";
import { ReactComponent as StakingIcon } from "../../assets/images/stakingBalanceIcon.svg";

import { supportedTokens, tokenImages } from "../../components/static/SupportedTokens";
import { slippageOptions } from "../../components/static/SlippageOptions";
import { getCalcOutData, getMasterPoolInfo, getMasterUserInfo, getTokensDetails, getUserData } from "../../io/kava";

import { useWeb3ConnectContext } from "../../hooks/web3ConnectContext";
import { toolTipData } from "../../components/static/ToolTipData";
import { ReactComponent as InfoTag } from "../../assets/images/info_outline.svg";
import config from "../../config.json";
import { getPendingReward, getWeightValue } from "../../components/CalcOutPut";
import { formatUnits } from "ethers/lib/utils";
import { useLazyQuery } from "@apollo/client";
import { TRAILING_PROFIT } from "../../io/subgraph";

const tradingTranchesData = [
  {
    title: "Senior Tranche",
    risk: "Low Risk",
    classname: "risk lowRisk",
    type: "senior",
  },
  {
    title: "Mezzanine Tranche",
    risk: "Moderate Risk",
    classname: "risk moderateRisk",
    type: "mezzanine",
  },
  {
    title: "Junior Tranche",
    risk: "High Risk",
    classname: "risk highRisk",
    type: "junior",
  },
];

const trancheStatusData = {
  columnTitle: [
    { title: "Asset", className: "" },
    { title: "Amount", className: "" },
    { title: "Value", className: "" },
    { title: "Utilization", className: "" },
    // { title: "Fees", className: "ps-0 text-center" },
  ],
  columnData: [
    { token: "BTC", image: tokenImages.BTC.image },
    { token: "ETH", image: tokenImages.ETH.image },
    { token: "USDT", image: tokenImages.USDT.image },
    { token: "FLR", image: tokenImages.FLR.image },
  ],
};

const Container = () => {
  const { loading, setCalcSwapOutDetails, calcSwapOutDetails, setUserData, userData, setLoading } =
    useCommonStateContext();
  const { account, chainId, status } = useMetaMask();

  useEffect(() => {
    if(account && chainId === config.CHAIN_ID) {
      setLoading(true)
    };
  }, [account, chainId]);

  const [poolInfo, setPoolInfo] = useState();
  const [userInfo, setUserInfo] = useState();

  const [isSwitchOn, setIsSwitchOn] = useState(false); // to switch balances between staking and wallet

  const [trancheConditions, setTrancheConditions] = useState({
    selectedToken: supportedTokens(userData?.balances)[0],
    slippage: slippageOptions[0],
    selectedTrancheTab: "buy",
    assetDetails: trancheStatusData.columnData,
  });
  const { name: trancheType } = useParams();

  const { accountBalance } = useWeb3ConnectContext();
  const [tranchePrice] = useLazyQuery(TRAILING_PROFIT);

  const trancheMetricsData = [
    {
      title: <p>Wallet Balance</p>,
      valueBalance: trancheConditions.trancheBalance,
      label: `${trancheType === "senior" ? "Senior" : trancheType === "junior" ? "Junior" : "Mezzanine"} ALP`,
      img: <WalletIcon />,
      tag: "Deposit",
    },
    {
      title: <p>Staking Balance</p>,
      valueBalance: trancheConditions.stakingBalance,
      label: `${trancheType === "senior" ? "Senior" : trancheType === "junior" ? "Junior" : "Mezzanine"} ALP`,
      img: <StakingIcon />,
      tag: "Withdraw",
    },
    {
      title: (
        <p className="d-flex align-items-center gap-1 mb-2">
          Assets Under Management
          <InfoTag className="info_icon tranche_info" data-tip={toolTipData.poolLiquidity} />
        </p>
      ),
      value: trancheConditions.totalDeposited !== undefined
        ? `$${Math.round(trancheConditions.totalDeposited).toLocaleString()}`
        : "-",
      img: <TotalDolarIcon />,
      desc: `${
        trancheConditions?.llpSupply !== undefined ? parseFloat(trancheConditions?.llpSupply?.toFixed(3)).toLocaleString() : "-"
      } ${trancheType === "senior" ? "Senior" : trancheType === "junior" ? "Junior" : "Mezzanine"} ALP`,
    },
    {
      title: (
        <p className="d-flex align-items-center gap-1 mb-2">
          7-day Trailing Profit <InfoTag className="info_icon tranche_info" data-tip={toolTipData.trancheApr} />
        </p>
      ),
      value: trancheConditions?.profit !== undefined ? `$${parseFloat(trancheConditions?.profit?.toFixed(3)).toLocaleString()}` : "-",
      img: <TotalPercentag />,
      desc: " ",
    },
  ];

  useEffect(() => {
    fetchTrancheConditions();
  }, [accountBalance, account, chainId, status]);

  useEffect(() => {
    if (
      calcSwapOutDetails !== undefined &&
      userData !== undefined &&
      poolInfo !== undefined &&
      userInfo !== undefined
    ) {
      // console.log('update calling......');
      updateTrancheDetails();
    }
  }, [
    isSwitchOn,
    trancheConditions.selectedToken,
    trancheType,
    trancheConditions.selectedTrancheTab,
    calcSwapOutDetails,
    userData,
    poolInfo,
    userInfo,
  ]);

  const fetchTrancheConditions = async () => {
    setLoading(true);
    // setUserData();
    // setCalcSwapOutDetails();
    if (accountBalance !== undefined && account !== null && chainId === configs.CHAIN_ID) {
      try {
        const { data: profitData } = await tranchePrice({
          context: { clientName: "analytics" },
          variables: { tranche: configs.tranches[trancheType] },
        });

        let sum = 0;
        if (profitData.trancheStats.length > 0) {
          profitData.trancheStats.map((val) => {
            sum += Number(val.totalPnL);
          });
          let feeCount =
            Number(profitData.trancheStats[0].totalFeeValue) -
            Number(profitData.trancheStats[profitData.trancheStats.length - 1].totalFeeValue);
          sum += feeCount;
        }
        if (accountBalance !== undefined && account !== null && chainId === configs.CHAIN_ID) {
        }
        const poolData = await getCalcOutData();
        setCalcSwapOutDetails(poolData);

        const userDetails = await getUserData(account);
        setUserData(userDetails);

        setPoolInfo();
        const masterPoolInfo = await getMasterPoolInfo(trancheType === "senior" ? 0 : trancheType === "junior" ? 2 : 1);
        setPoolInfo(masterPoolInfo);

        setUserInfo();
        const masterUserInfo = await getMasterUserInfo(
          trancheType === "senior" ? 0 : trancheType === "junior" ? 2 : 1,
          account
        );
        setUserInfo(masterUserInfo);

        // console.log("fetchTranch:calcSwap", calcSwapOutDetails);

        let assetDetails = [];
        trancheStatusData.columnData.map((item) => {
          let _poolAmt = poolData.trancheAssetInfo[`${trancheType}${item.token}`].poolAmount;
          let _reservedAmt = poolData.trancheAssetInfo[`${trancheType}${item.token}`].reservedAmount;

          item.amount = _poolAmt;
          item.value = Number(poolData.prices[item.token]) * _poolAmt;
          item.utilization = _reservedAmt > 0 ? (_reservedAmt / _poolAmt) * 100 : 0;
          assetDetails.push(item);
        });

        setTrancheConditions({
          ...trancheConditions,
          assetDetails,
          profit: sum,
        });
        setLoading(false);
      } catch (err) {
        console.log("liquidity tranche err--", err);
        setLoading(false);
      }
    }
    console.log('liqTra:status', status)
    if (status === 'notConnected' 
      || status === 'unavailable'
      || (status === 'connected' && chainId!==config.CHAIN_ID)){
      setLoading(false);
    }

    //setLoading(false);
  };
  // console.log('userData---',userData);
  const updateTrancheDetails = () => {
    const trancheAssetAmount =
      calcSwapOutDetails.trancheAssetInfo[`${trancheType}${trancheConditions.selectedToken.value}`];
    const weightValue =
      calcSwapOutDetails.totalPoolValue !== 0
        ? (getWeightValue(calcSwapOutDetails, trancheConditions.selectedToken.value) /
        calcSwapOutDetails.totalPoolValue) *
        100
        : 0;
    const targetValue = (
      (calcSwapOutDetails.targetWeights[trancheConditions.selectedToken.value] / calcSwapOutDetails.totalWeight) *
      100
    ).toFixed(2);
    const totalDeposited = calcSwapOutDetails.trancheValues[trancheType];
    const llpSupply = calcSwapOutDetails.lpSupply[trancheType];
    const approvedTokenAmount = isSwitchOn
      ? userData.approvedLampMaster[trancheConditions.selectedToken.value]
      : userData.approvedLiquidityRouter[trancheConditions.selectedToken.value];
    const approvedTrancheAmount = userData.approvedTrancheLiquidityRouter[trancheType];
    const approvedDepositAmount = userData.approvedTrancheLampMaster[trancheType];
    const trancheBalance = userData.trancheBalances[trancheType];
    const tokenBalance = userData.balances[trancheConditions.selectedToken.value];
    const stakingBalance = userData.trancheStakingBalances[trancheType];
    const unClaimRewards = getPendingReward(calcSwapOutDetails.trancheLampBalances[trancheType], poolInfo, userInfo);
    const dailyLampRewards = Math.round(
      (config.rewardPerSecond * 86400 * poolInfo.allocPoint) / config.totalAllocPoint
    );
    //console.log("trancheConditions.tokenFunds-", trancheConditions.tokenFunds);
    const tokenFund = trancheAssetAmount.poolAmount - trancheAssetAmount.reservedAmount;
    // console.log("FE COUNT unClaimRewards--", unClaimRewards);
    setTrancheConditions({
      ...trancheConditions,
      tranchePrice: llpSupply !== 0 ? totalDeposited / llpSupply : 1,
      tokenBalance,
      tokenFund,
      trancheBalance,
      approvedTokenAmount,
      approvedTrancheAmount,
      approvedDepositAmount,
      llpSupply,
      stakingBalance,
      totalDeposited,
      weightValue,
      targetValue,
      unClaimRewards,
      dailyLampRewards,
    });
  };

  return (
    <div className="swap liquidity-details-main container">
      <Loader loading={loading} />
      <Page
        trancheMetricsData={trancheMetricsData}
        trancheStatusData={trancheStatusData}
        tradingTranchesData={tradingTranchesData}
        trancheType={trancheType}
        trancheConditions={trancheConditions}
        setTrancheConditions={setTrancheConditions}
        isSwitchOn={isSwitchOn}
        setIsSwitchOn={setIsSwitchOn}
        setUserInfo={setUserInfo}
        setPoolInfo={setPoolInfo}
        fetchTrancheConditions={fetchTrancheConditions}
      />
    </div>
  );
};

export default Container;
