// all the business logic will go here in container
// the page will be passed with all the props needed inside

import { useEffect, useState } from "react";
import { useMetaMask } from "metamask-react";
import config from "../../config.json";
import { useCommonStateContext } from "../../hooks/commonStateContext";

import Page from "./Page";
import Loader from "../../components/loader/Loader";

import btcoinIcon from "../../assets/images/bitcoin.png";
import tetherIcon from "../../assets/images/usdt-coin.png";
import ethIcon from "../../assets/images/ethereum-coin.svg";
import kavaIcon from "../../assets/images/flare_coin_logo.png";

import { getCalcOutData, getTranchesDetails } from "../../io/kava";
import { useWeb3ConnectContext } from "../../hooks/web3ConnectContext";

import { ReactComponent as InfoTag } from "../../assets/images/info_outline.svg";
import { toolTipData } from "../../components/static/ToolTipData";
import { getWeightValue } from "../../components/CalcOutPut";
import configs from "../../config.json";
import { useLazyQuery } from "@apollo/client";
import { TRAILING_PROFIT } from "../../io/subgraph";

const lampEthData = [
  {
    title: "AIPX/FLR",
    value: "1 AIPX = 500 FLR",
    earnTitle: "Earn",
    earnValue: "100 LAMP",
    aprTitle: "APR",
    aprValue: "210.54%",
    totalDeposited: "Total Deposited",
    totalDepositedValue: "$28,018.42",
    volume: "24h Volume",
    volumeValue: "$23,846.57",
  },
];

const tradingTrancheData = [
  {
    title: "Senior Tranche",
    risk: "Low Risk",
    classname: "risk lowRisk",
    type: "senior",
    value: "Senior Tranche",
    aprValue: 210.54,
  },
  {
    title: "Mezzanine Tranche",
    risk: "Moderate Risk",
    classname: "risk moderateRisk",
    type: "mezzanine",
    value: "Mezzanine Tranche",
    aprValue: 210.54,
  },
  {
    title: "Junior Tranche",
    risk: "High Risk",
    classname: "risk highRisk",
    type: "junior",
    value: "Junior Tranche",
    aprValue: 210.54,
  },
];

const tokenData = {
  columnTitle: [
    { title: "Token", className: "" },
    { title: "Price", className: "" },
    { title: "Amount", className: "" },
    { title: "Value", className: "" },
    {
      title: (
        <span className="d-flex align-items-center gap-1">
          Weight/Target <InfoTag className="info_icon" data-tip={toolTipData.targetWeight} />
        </span>
      ),
      className: "",
    },
    {
      title: (
        <span className="d-flex align-items-center justify-content-center gap-1">
          Utilization <InfoTag className="info_icon" data-tip={toolTipData.utilization} />
        </span>
      ),
      className: "",
    },
  ],
  columnData: [
    { token: "BTC", image: btcoinIcon },
    { token: "ETH", image: ethIcon },
    { token: "USDT", image: tetherIcon },
    { token: "FLR", image: kavaIcon },
  ],
};

const Container = () => {
  const { account, chainId, status } = useMetaMask();
  const { setLoading, loading, setCalcSwapOutDetails, calcSwapOutDetails } = useCommonStateContext();
  const { accountBalance } = useWeb3ConnectContext();
  const [tranchesDetails, setTranchesDetails] = useState(tradingTrancheData);
  const [tokensDetails, setTokensDetails] = useState(tokenData.columnData);
  const [tranchePrice] = useLazyQuery(TRAILING_PROFIT);
  
  useEffect(() => {
   // if(account && chainId === config.CHAIN_ID) {
      setLoading(true)
    //};
  }, [account, chainId]);

  useEffect(() => {
    fetchTrancheAndTokenData();
  }, [accountBalance, account, chainId, status]);

  useEffect(() => {
    if (calcSwapOutDetails !== undefined) {
      updateTrancheAndTokenData();
    }
  }, [calcSwapOutDetails]);

  const fetchTrancheAndTokenData = async () => {
    // setLoading(true);

    // console.log('liqUpData--',liqUpData);
    // setTranchesDetails(liqUpData)
    if (accountBalance !== undefined && account !== null && chainId === configs.CHAIN_ID) {
      console.log("liquidity:fetchTranch.. exe");
      try {
        let tranchesData = await getTranchesDetails(tradingTrancheData);
        // console.log("tranchesData--", tranchesData);

        let trancheProfits = {};
        for (let i = 0; i < tranchesData.length; i++) {
          const { data: profitData } = await tranchePrice({
            context: { clientName: "analytics" },
            variables: { tranche: configs.tranches[tranchesData[i].type] },
          });
          trancheProfits[tranchesData[i].type] = profitData.trancheStats;
        }

        const trancheProfitData = [];
        tranchesData.map((elem) => {
          let sum = 0;
          if (trancheProfits[elem.type].length > 0) {
            trancheProfits[elem.type].map((val) => {
              sum += Number(val.totalPnL);
            });
            let feeCount =
              Number(trancheProfits[elem.type][0].totalFeeValue) -
              Number(trancheProfits[elem.type][trancheProfits[elem.type].length - 1].totalFeeValue);
            sum += feeCount;
          }
          trancheProfitData.push({
            ...elem,
            profit: sum,
          });
        });

        setTranchesDetails(trancheProfitData);

        const poolData = await getCalcOutData();
        setCalcSwapOutDetails(poolData);
        setLoading(false);
      } catch (err) {
        console.log("liquidity err--", err);
        setLoading(false);
      }
    }
    if (
      status === "notConnected" ||
      status === "unavailable" ||
      (status === "connected" && chainId !== config.CHAIN_ID)
    ) {
      setLoading(false);
    }
  };

  const updateTrancheAndTokenData = () => {
    let tranchesData = tranchesDetails.map((elem) => {
      elem.price = calcSwapOutDetails.trancheValues[elem.type] / calcSwapOutDetails.lpSupply[elem.type];
      elem.liquidity = calcSwapOutDetails.trancheValues[elem.type];
      return elem;
    });
    setTranchesDetails(tranchesData);
    // console.log('calcSwapOutDetails==',calcSwapOutDetails);
    const tokensData = tokenData.columnData.map((elem) => {
      let _poolAmt = calcSwapOutDetails.assetInfo[elem.token].poolAmount;
      let _reservedAmt = calcSwapOutDetails.assetInfo[elem.token].reservedAmount;
      let _price = calcSwapOutDetails.prices[elem.token];

      elem.price = _price;
      elem.amount = _poolAmt;
      elem.value = _price * _poolAmt;
      elem.utilization = _reservedAmt > 0 ? (_reservedAmt / _poolAmt) * 100 : 0;
      elem.target = ((calcSwapOutDetails.targetWeights[elem.token] / calcSwapOutDetails.totalWeight) * 100).toFixed(2);
      elem.weight = (getWeightValue(calcSwapOutDetails, elem.token) / calcSwapOutDetails.totalPoolValue) * 100;
      // console.log('aum--elem.test-',elem.test);
      return elem;
    });
    setTokensDetails(tokensData);
  };

  return (
    <div className="swap container">
      <Loader loading={loading} />
      <Page
        lampEthData={lampEthData}
        tokenData={tokenData}
        tokensDetails={tokensDetails}
        tranchesDetails={tranchesDetails}
      />
    </div>
  );
};

export default Container;
