import { Col } from "react-bootstrap";

import kava from "../../../assets/images/flare_coin_logo.png";

import PlButton from "../../../components/buttons/Button";
import WithTagSelect from "../../../components/inputFields/WithTagSelect";
import WithTag from "../../../components/inputFields/WithTag";

const Deposit = () => {
  return (
    <>
      <Col className="mt-2">
        <div className="shadowed-box card-main-content">
          <div className="card-title mb-3">
            <h3>DEPOSIT</h3>
          </div>
          <div className="mt-4">
            <WithTag
              label={"From"}
              placeholder={"0.0"}
              tag={
                <div className="d-flex align-items-center gap-1">
                  <img src={kava} height={14} width={14} /> FLR
                </div>
              }
            />
            <div className="d-flex justify-content-between align-items-center mt-2">
              <span className="balance">Bal: 0.345 FLR</span>
              <div className="d-flex gap-2">
                <PlButton type={"percentageButton"} label={"25%"} />
                <PlButton type={"percentageButton"} label={"50%"} />
                <PlButton type={"percentageButton"} label={"75%"} />
                <PlButton type={"percentageButton"} label={"100%"} />
              </div>
            </div>
          </div>
          <div className="mt-4">
            <WithTag
              label={"To"}
              placeholder={"0.0"}
              disabled={true}
              tag={
                <div className="d-flex align-items-center gap-1">
                  <div className="lamp_tag eth_lamp"></div>
                  <img src={kava} className="trade_head_img" height={12} width={12} /> FLR/AIPX
                </div>
              }
            />
          </div>

          <div className="mt-4">
            <p className="d-flex justify-content-between align-items-center mb-1">
              Est. Allocated LAMP <span className="fw-600">XX</span>
            </p>
            <p className="d-flex justify-content-between align-items-center mb-1">
              Est. Allocated ETH <span className="fw-600">XX</span>
            </p>
            <p className="d-flex justify-content-between align-items-center mb-1">
              Minimum Received <span className="fw-600">XX LAMP/ETH</span>
            </p>
            <p className="d-flex justify-content-between align-items-center mb-1">
              Est. Daily Income <span className="fw-600">XX LAMP</span>
            </p>
            <p className="d-flex justify-content-between align-items-center mb-1">
              Price Impact <span className="fw-600">XX%</span>
            </p>
            <p className="d-flex justify-content-between align-items-center mb-1">
              Slippage{" "}
              <span className="fw-600">
                <WithTagSelect
                  isInput={false}
                  optionValue={[
                    { label: "0.3%", value: "0.3" },
                    { label: "0.5%", value: "0.5" },
                    { label: "0.7%", value: "0.7" },
                  ]}
                />
              </span>
            </p>
          </div>

          <div className="mt-4">
            <PlButton label={"DEPOSIT"} />
          </div>
        </div>
      </Col>
    </>
  );
};

export default Deposit;
