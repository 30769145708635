import { cssTransition } from "react-toastify";

const swirl = cssTransition({
  enter: "translate-in-fwd",
  exit: "fade-out-bck"
});

export const toastOptions = {
  type: "success",
  autoClose: 3000,
  position: "bottom-center",
  closeButton: false,
  hideProgressBar: true,
  closeOnClick: false,
  icon: false,
  transition: swirl,
  style: { color: "#38D900", backgroundColor: "#252927" },
};