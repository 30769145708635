import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { MetaMaskProvider } from "metamask-react";
import { ApolloClient, ApolloLink, ApolloProvider, HttpLink, InMemoryCache } from "@apollo/client";

import "bootstrap/dist/css/bootstrap.min.css";
import CommonStateContextProvider from "./hooks/commonStateContext";
import TradeTokenContextProvider from "./hooks/tradeTokenContext";
import Web3ConnectContextProvider from "./hooks/web3ConnectContext";

const DefaultLink = new HttpLink({
  uri: "http://139.59.69.224:8000/subgraphs/name/aipx-trade",
});

const analyticsLink = new HttpLink({
  uri: "http://139.59.69.224:8000/subgraphs/name/aipx-analytics",
});

const client = new ApolloClient({
  link: ApolloLink.split(
    (operation) => operation.getContext().clientName === "analytics",
    analyticsLink,
      DefaultLink,
  ),
  cache: new InMemoryCache(),
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <MetaMaskProvider>
    <BrowserRouter>
      <Web3ConnectContextProvider>
        <CommonStateContextProvider>
          <TradeTokenContextProvider>
            <ApolloProvider client={client}>
              <App />
            </ApolloProvider>
          </TradeTokenContextProvider>
        </CommonStateContextProvider>
      </Web3ConnectContextProvider>
    </BrowserRouter>
  </MetaMaskProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
