// all the business logic will go here in container
// the page will be passed with all the props needed inside

import { useEffect, useState } from "react";
import { useMetaMask } from "metamask-react";
import { useLazyQuery } from "@apollo/client";
import Loader from "../../components/loader/Loader";
import { useCommonStateContext } from "../../hooks/commonStateContext";
import { useTradeTokenContext } from "../../hooks/tradeTokenContext";
import configs from "../../config.json";
import Page from "./Page";

import { getCalcOutData, getUserData } from "../../io/kava";
import { TRADE_VOLUME, GET_BLOCK, GET_CHART_PRICE } from "../../io/subgraph";

import { posSizeTokens, supportedTokens } from "../../components/static/SupportedTokens";
import { slippageOptions } from "../../components/static/SlippageOptions";
import { useWeb3ConnectContext } from "../../hooks/web3ConnectContext";
import config from "../../config.json";
import { _getPositionKey } from "../../components/CalcOutPut";
import { timeConditions } from "../../components/static/TimeOptions";
import { ethers } from "ethers";

const Container = () => {
  const { setLoading, loading, setCalcSwapOutDetails, calcSwapOutDetails, setUserData, userData } =
    useCommonStateContext();
  const { accountBalance } = useWeb3ConnectContext();
  const { account, chainId, status } = useMetaMask();

  useEffect(() => {
    if (account && chainId === config.CHAIN_ID) {
      setLoading(true);
    }
  }, [account, chainId]);

  const [takeQuery] = useLazyQuery(GET_CHART_PRICE);

  // If the user selects a pair directly from the dashboard page, this token will be displayed in the position size input field.
  const { tradeToken } = useTradeTokenContext();

  // To save the selected leverage amount from the range input.
  const [leverage, setLeverage] = useState(2);

  //To store the current price and last 24-hour changes of the selected token in order to display them above the chart.
  const [tradingDetails, setTradingDetails] = useState({});

  //user input the amount to swap
  const [amountIn, setAmountIn] = useState("");

  const [ownerPositions, setOwnerPositions] = useState();

  // to set all details of selected tokens and tab
  const [tradeConditions, setTradeConditions] = useState({
    //selected token in amount inputField
    selectedFrom: supportedTokens(userData?.balances)[0],
    //selected token in position size inputField
    selectedTo: tradeToken ? posSizeTokens.find((token) => token.value === tradeToken) : posSizeTokens[2],
    slippage: slippageOptions[0],
    selectedTradeTab: "long",
  });

  const [stats] = useLazyQuery(TRADE_VOLUME, {
    context: { clientName: "analytics" },
  });
  const [blocks] = useLazyQuery(GET_BLOCK, {
    context: { clientName: "analytics" },
  });

  const fetchTradeConditions = async () => {
    setLoading(true);
    if (accountBalance !== undefined && account !== null && chainId === config.CHAIN_ID) {
      try {
        const poolData = await getCalcOutData();
        setCalcSwapOutDetails(poolData);

        const userDetails = await getUserData(account);
        // console.log("userDetails--", userDetails);
        setUserData(userDetails);
        setLoading(false);
      } catch (err) {
        console.log("trade err--", err);
        setLoading(false);
      }
    }
    if (
      status === "notConnected" ||
      status === "unavailable" ||
      (status === "connected" && chainId !== config.CHAIN_ID)
    ) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTradeConditions();
  }, [accountBalance, account, chainId, status]);

  useEffect(() => {
    if (userData !== undefined && calcSwapOutDetails !== undefined) {
      updateTokenDetails();
    }
  }, [
    userData,
    calcSwapOutDetails,
    tradeConditions.selectedFrom,
    tradeConditions.selectedTo,
    tradeConditions.selectedTradeTab,
  ]);

  useEffect(() => {
    fetchIndexTokenDetails();
  }, [tradeConditions.selectedTo]);

  const updateTokenDetails = () => {
    const TokenAPrice = calcSwapOutDetails.prices[tradeConditions.selectedFrom.value];
    const tokenBalance = userData.balances[tradeConditions.selectedFrom.value];
    const approvedAmount = userData.approvedOrderManager[tradeConditions.selectedFrom.value];
    const borrowFeeRate =
      tradeConditions.selectedTradeTab === "long"
        ? (calcSwapOutDetails.assetInfo[tradeConditions.selectedTo.value].reservedAmount /
            calcSwapOutDetails.assetInfo[tradeConditions.selectedTo.value].poolAmount) *
          0.01
        : (calcSwapOutDetails.assetInfo.USDT.reservedAmount / calcSwapOutDetails.assetInfo.USDT.poolAmount) * 0.01;
    const entryPrice = calcSwapOutDetails.prices[tradeConditions.selectedTo.value];
    const availableLiquidity =
      tradeConditions.selectedTradeTab === "long"
        ? calcSwapOutDetails.assetInfo[tradeConditions.selectedTo.value].poolAmount -
          calcSwapOutDetails.assetInfo[tradeConditions.selectedTo.value].reservedAmount
        : calcSwapOutDetails.assetInfo.USDT.poolAmount - calcSwapOutDetails.assetInfo.USDT.reservedAmount;
    const maxLeverage = config.maxLeverage;
    const positionFeeRate = calcSwapOutDetails.fees.positionFee / config.PRECISION;
    const executionFee = calcSwapOutDetails.fees.executionFee;
    const swapExecutionFee = calcSwapOutDetails.fees.swapExecutionFee;
    const txExecutionFee =
      tradeConditions.selectedTradeTab === "long"
        ? tradeConditions.selectedFrom.value !== tradeConditions.selectedTo.value
          ? Number(executionFee) + Number(swapExecutionFee)
          : executionFee
        : tradeConditions.selectedFrom.value !== "USDT"
        ? Number(executionFee) + Number(swapExecutionFee)
        : executionFee;

    setTradeConditions({
      ...tradeConditions,
      entryPrice,
      availableLiquidity,
      maxLeverage,
      positionFeeRate,
      TokenAPrice,
      tokenBalance,
      approvedAmount,
      borrowFeeRate,
      executionFee,
      txExecutionFee,
      swapExecutionFee,
    });
    const posKey = _getPositionKey(
      account,
      tradeConditions.selectedTo.value,
      tradeConditions.selectedTradeTab === "long" ? 0 : 1
    );
    setOwnerPositions(userData.userPositions[posKey]);
  };

  const fetchIndexTokenDetails = async () => {
    const currentTime = Date.now() / 1000;
    let tokenPriceChangeDetails;

    const { data: tokenPriceData } = await takeQuery({
      variables: {
        start: Math.round(currentTime - timeConditions["24H"].timeGap),
        end: Math.round(currentTime),
        period: timeConditions["24H"].periodName,
        from: configs.tokens[tradeConditions.selectedTo.value],
        to: configs.tokens[tradeConditions.selectedTo.value],
      },
      context: { clientName: "analytics" },
    });
    let prices = [];
    tokenPriceData.tokenFrom.map((elem) => {
      prices.push(
        Number(ethers.utils.formatUnits(elem.value, 30 - configs.decimals[tradeConditions.selectedTo.value]))
      );
    });

    tokenPriceChangeDetails = {
      token: tradeConditions.selectedTo.value,
      change: prices[prices.length - 1] - prices[0],
      open: prices[0],
      high: Math.max(...prices),
      low: Math.min(...prices),
      close: prices[prices.length - 1],
    };

    const { data: blockData } = await blocks();

    // To obtain the price changes of the last 24 hours.
    const { data } = await stats({
      variables: {
        b24: Number(blockData._meta.block.number) - configs.blockCount,
      },
    });

    const currentVolume = `trade${configs.tokens[tradeConditions.selectedTo.value].toLowerCase()}`;
    const pastVolume = `trade${configs.tokens[tradeConditions.selectedTo.value].toLowerCase()}yesterday`;

    const volumeData = {
      volume:
        data[currentVolume].length > 0 && data[pastVolume].length > 0
          ? (Number(data[currentVolume][0].volume) - Number(data[pastVolume][0].volume)) / configs.divideVolume
          : 0,
      usdVolume:
        data[currentVolume].length > 0 && data[pastVolume].length > 0
          ? (Number(data[currentVolume][0].volumeUsd) - Number(data[pastVolume][0].volumeUsd)) / configs.divideValue
          : 0,
    };

    tokenPriceChangeDetails = {
      ...tokenPriceChangeDetails,
      ...volumeData,
      price: tokenPriceChangeDetails.close,
      changePercentage: (tokenPriceChangeDetails.change / tokenPriceChangeDetails.close) * 100,
    };
    // console.log("tokenPriceChangeDetails--", tokenPriceChangeDetails);
    setTradingDetails(tokenPriceChangeDetails);
  };

  return (
    <div className="trade container">
      <Loader loading={loading} />
      <Page
        tradingDetails={tradingDetails}
        tradeConditions={tradeConditions}
        setTradeConditions={setTradeConditions}
        amountIn={amountIn}
        setAmountIn={setAmountIn}
        leverage={leverage}
        setLeverage={setLeverage}
        ownerPositions={ownerPositions}
        fetchTradeConditions={fetchTradeConditions}
      />
    </div>
  );
};

export default Container;
