import React, { useEffect, useState } from "react";
import { useMetaMask } from "metamask-react";
import configs from "../../config.json";
import { useCommonStateContext } from "../../hooks/commonStateContext";
import { formatUnits } from "ethers/lib/utils";

import { ReactComponent as DownArrowIcon } from "../../assets/images/down-arrow-button.svg";
import { ReactComponent as UpArrowIcon } from "../../assets/images/up-arrow-button.svg";
import { ReactComponent as CloseIcon } from "../../assets/images/close-icon.svg";

import {
  approve,
  getUserData,
  placeOrder,
} from "../../io/kava";

import { slippageOptions } from "../static/SlippageOptions";
import { orderTypes } from "../static/OrderTypes";
import { tokenImages } from "../static/SupportedTokens";
import WithTag from "../inputFields/WithTag";
import PlButton from "../buttons/Button";

import CloseConfirmation from "../modals/tradeModals/CloseConfirmation";
import Withdraw from "../modals/tradeModals/Withdraw";
import Deposit from "../modals/tradeModals/Deposit";

import { waitingToast } from "../toasts/Waiting";
import { successToast } from "../toasts/Success";
import { handleError } from "../toasts/Error";
import { useWeb3ConnectContext } from "../../hooks/web3ConnectContext";
import CustomPagination from "../CustomPagination";
import { _getLiquidationPrice, calcDecreasePosition, calcIncreasePosition } from "../CalcOutPut";


const positionColumnTitles = ["Position", "Size", "Net Value", "Market Price", "Entry Price", "Liquidation Price", "Collateral Asset", "Action"];

const PositionsTab = ({ tableData, setOrderMessage, setCompleteTransactionModal }) => {
  const { account } = useMetaMask();
  const { accountBalance } = useWeb3ConnectContext();
  const { setLoading, setExplorerURL, calcSwapOutDetails, userData, setUserData } = useCommonStateContext();

  //to manage data for deposit modal
  const [showDepositModal, setShowDepositModal] = useState(false);
  const [depositOrder, setDepositOrder] = useState();
  const [depositAmount, setDepositAmount] = useState("");

  //to manage data for withdraw modal
  const [showWithdrawModal, setShowWithdrawModal] = useState(false);
  const [withdrawOrder, setWithdrawOrder] = useState();
  const [withdrawAmount, setWithdrawAmount] = useState("");

  //to manage data for close position modal
  const [showCloseModal, setShowCloseModal] = useState(false);
  const [closeOrder, setCloseOrder] = useState();
  const [closingSize, setClosingSize] = useState("");
  const [orderType, setOrderType] = useState(orderTypes[0]);
  const [reduceType, setReduceType] = useState("reduceCollateral");

  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    if (showWithdrawModal) updateWithdrawValues();
  }, [withdrawAmount, showWithdrawModal]);

  useEffect(() => {
    if (showDepositModal) updateDepositValues();
  }, [depositAmount, showDepositModal]);

  useEffect(() => {
    if (showCloseModal) updateClosingValues();
  }, [closingSize, showCloseModal, orderType, reduceType, closeOrder?.limitMarketPrice]);

  //console.log('posTab:withOrd-', withdrawOrder)
  const updateWithdrawValues = async () => {
    // let newCollateralValue = Number(withdrawOrder.collateral) - Number(withdrawAmount);

    //console.log('posTab:withOrd-', withdrawOrder)
    console.log("updWith:balance", withdrawOrder.balance);
    const updatedValues = calcDecreasePosition(
      account,
      withdrawOrder.collateralAsset,
      0,
      Number(withdrawAmount),
      withdrawOrder.side,
      withdrawOrder.indexAsset,
      Number(withdrawOrder.slippage.value),
      false,
      Number(withdrawOrder.marketPrice),
      calcSwapOutDetails,
      withdrawOrder.ownerPosition,
    );

    setWithdrawOrder({
      ...withdrawOrder,
      ...updatedValues,
    });
  };

  const updateDepositValues = async () => {

    console.log(">>>>>> updateDepositValues");
    console.log("   collAss-", depositOrder.collateralAsset);
    console.log("   amountIn-", Number(depositAmount));
    console.log("   leverage-", depositOrder.leverage);
    console.log("   side-", depositOrder.side);
    console.log("   index-", depositOrder.indexAsset);
    console.log("   markPrc-", depositOrder.marketPrice);
    console.log("   pos-", depositOrder.ownerPosition);

    const { newCollateralValue, collateralValue } = calcIncreasePosition(
      account,
      depositOrder.collateralAsset,
      Number(depositAmount),
      depositOrder.leverage,
      depositOrder.side,
      depositOrder.indexAsset,
      false,
      depositOrder.marketPrice,
      calcSwapOutDetails,
      depositOrder.ownerPosition,
      true,
    );

    const newCollateral = collateralValue <= 0
      ? depositOrder.ownerPosition.collateralValue : newCollateralValue;

    let newLeverage = Number(depositOrder.sizeChangeValue) / newCollateral;
    console.log("   newLeverage", newLeverage);
    let totalFeeValue = depositOrder.closeFee + depositOrder.borrowFee + calcSwapOutDetails.fees.liquidationFee;
    let newLiquidationPrice = _getLiquidationPrice(
      depositOrder.side,
      depositOrder.priceTrigger,
      newCollateral,
      Number(depositOrder.sizeChangeValue),
      totalFeeValue,
    );
    console.log("   newLiquidationPrice", newLiquidationPrice);

    setDepositOrder({
      ...depositOrder,
      newLiquidationPrice,
      newCollateralValue: newCollateral,
      newLeverage,
      newNetValue: newCollateral + depositOrder.estimatedPnl - depositOrder.closeFee - depositOrder.borrowFee,
    });
  };

  const updateClosingValues = async () => {
    console.log("posTab:updClosVal reduceTab-", reduceType);
    // console.log("posTab:updClosVal closeOrder-", closeOrder)

    const updatedValues = calcDecreasePosition(
      account,
      closeOrder.collateralAsset,
      Number(closingSize),
      reduceType === "reduceCollateral" ? Number(closingSize) / Number(closeOrder.leverage) : 0,
      closeOrder.side,
      closeOrder.indexAsset,
      Number(closeOrder.slippage.value),
      orderType.value !== "marketOrder",
      Number(closeOrder.limitMarketPrice),
      calcSwapOutDetails,
      closeOrder.ownerPosition,
    );

    console.log("posTab:updClosVal updatedVals-", updatedValues);

    setCloseOrder({
      ...closeOrder,
      ...updatedValues,
    });
  };

  const handleShowDeposit = async (order) => {
    setDepositAmount("");
    setDepositOrder({
      ...order,
      executionFee: calcSwapOutDetails.fees.executionFee,
      balance: userData.balances[order.collateralAsset],
      approvedBalance: userData.approvedOrderManager[order.collateralAsset],
      slippage: slippageOptions[0],
    });
    setShowDepositModal(true);
  };

  const handleShowWithdraw = async (order) => {
    setWithdrawAmount("");
    let orderSize = formatUnits(order.size, 30);
    // console.log("handShowWith:orderSize", orderSize);
    // console.log("handShowWith:leverage", configs.maxLeverage)
    // console.log("handShowWith:minPosSize", orderSize/configs.maxLeverage)
    setWithdrawOrder({
      ...order,
      executionFee: calcSwapOutDetails.fees.executionFee,
      balance: Number(order.netValue) - 5,
      // balance: Number(order.netValue) - Math.max(5, orderSize/configs.maxLeverage),
      slippage: slippageOptions[0],
    });
    setShowWithdrawModal(true);
  };

  const handleShowCloseModal = async (order) => {
    setClosingSize("");
    console.log("handShowCl:ordBef ", closeOrder);
    setCloseOrder({
      ...order,
      slippage: slippageOptions[0],
      limitMarketPrice: order.marketPrice,
      executionFee: calcSwapOutDetails.fees.executionFee,
      minimumReceived: 0,
    });
    console.log("handShowCl:ordAft ", closeOrder);
    setShowCloseModal(true);
  };

  const handleApprove = async () => {
    setLoading(true);
    try {
      // waitingToast(`Approving ${depositOrder.collateralAsset}`);

      await approve(depositOrder.collateralAsset, configs.trade.orderManager, depositAmount);

      successToast(`${depositOrder.collateralAsset} Approved!`);

      const userDetails = await getUserData(account);
      setUserData(userDetails);

      setDepositOrder({
        ...depositOrder,
        approvedBalance: userDetails.approvedOrderManager[depositOrder.collateralAsset],
      });
    } catch (e) {
      handleError(e);
    }
    setLoading(false);
  };

  const handleDeposit = async () => {
    setLoading(true);
    let toastMessage;
    if (depositOrder.side === 0) {
      toastMessage = `Requested to Deposit $${parseFloat(
        (Number(depositAmount) * Number(depositOrder.marketPrice)).toFixed(3),
      )} to ${depositOrder.indexAsset} LONG`;
    } else {
      const price = calcSwapOutDetails.prices[configs.stableCoin];
      toastMessage = `Requested to deposit $${parseFloat(
        (Number(depositAmount) * Number(price)).toFixed(3),
      )} to ${depositOrder.indexAsset} SHORT`;
    }

    try {
      // waitingToast(toastMessage);

      const _triggerPrice = depositOrder.side === 0
        ? Number(depositOrder.marketPrice) * (1 + Number(depositOrder.slippage.value) / 100)
        : Number(depositOrder.marketPrice) * (1 - Number(depositOrder.slippage.value) / 100);

      const res = await placeOrder(
        0, // updateType (increase)
        depositOrder.side, // side (long = 0 / short =1)
        depositOrder.indexAsset, //index token
        depositOrder.collateralAsset, // collateral token
        0, // order type (market)
        _triggerPrice, // order price
        depositOrder.collateralAsset, // pay token
        depositAmount, // purchase / collateral amount
        0, //size change
        calcSwapOutDetails.fees.positionFee / configs.PRECISION,
        depositOrder.executionFee,
        toastMessage,
      );

      successToast(toastMessage);
      setOrderMessage(toastMessage);
      //This URL needs to be updated according to the Kava Explorer.
      setExplorerURL(`https://coston2-explorer.flare.network/tx/${res.hash}`);
      setCompleteTransactionModal(true);
    } catch (e) {
      handleError(e);
    }
    setLoading(false);
  };

  const handleWithdraw = async () => {
    setLoading(true);

    const toastMessage = `Requested to Withdraw $${parseFloat(Number(withdrawAmount).toFixed(3))} from ${
      withdrawOrder.indexAsset
    } ${withdrawOrder.side === 0 ? "LONG" : "SHORT"}`;

    try {
      // waitingToast(toastMessage);

      const _triggerPrice = withdrawOrder.side === 0
        ? Number(withdrawOrder.marketPrice) * (1 - Number(withdrawOrder.slippage.value) / 100)
        : Number(withdrawOrder.marketPrice) * (1 + Number(withdrawOrder.slippage.value) / 100);

      const res = await placeOrder(
        1, // updateType (decrease)
        withdrawOrder.side, // side (long = 0 / short =1)
        withdrawOrder.indexAsset, //index token
        withdrawOrder.collateralAsset, // collateral token
        0, // order type (market)
        _triggerPrice, // order price
        withdrawOrder.collateralAsset, // pay token
        withdrawAmount * calcSwapOutDetails.prices[configs.stableCoin], // purchase / collateral amount
        0, //size change
        calcSwapOutDetails.fees.positionFee / configs.PRECISION,
        withdrawOrder.executionFee,
        toastMessage,
      );

      successToast(toastMessage);
      setOrderMessage(toastMessage);
      //This URL needs to be updated according to the Kava Explorer.
      setExplorerURL(`https://coston2-explorer.flare.network/tx/${res.hash}`);
      setCompleteTransactionModal(true);
    } catch (e) {
      handleError(e);
    }
    setLoading(false);
  };

  const handleCloseOrder = async () => {
    setLoading(true);
    const toastMessage = `Requested to Decrease ${closeOrder.indexAsset} ${closeOrder.side === 0 ? "LONG" : "SHORT"} by $${parseFloat(Number(closingSize).toFixed(3))}`;
    try {
      // waitingToast(toastMessage);

      const _triggerPrice = closeOrder.side === 0
        ? Number(closeOrder.marketPrice) * (1 - Number(closeOrder.slippage.value) / 100)
        : Number(closeOrder.marketPrice) * (1 + Number(closeOrder.slippage.value) / 100);

      const res = await placeOrder(
        1, // updateType (decrease)
        closeOrder.side, // side (long = 0 / short =1)
        closeOrder.indexAsset, //index token
        closeOrder.collateralAsset, // collateral token
        orderType.value === "marketOrder" ? 0 : 1, // order type (market = 0 / limit = 1)
        orderType.value === "marketOrder" ? _triggerPrice : closeOrder.limitMarketPrice, // order price
        closeOrder.collateralAsset, // pay token
        Number(closeOrder.collateral) - closeOrder.newCollateralValue,// purchase / collateral amount
        // (reduceType === "reduceCollateral" || Number(closeOrder.newLeverage) === 0) ? Number(closingSize) / Number(closeOrder.leverage) : 0,
        closingSize, //size change
        calcSwapOutDetails.fees.positionFee / configs.PRECISION,
        closeOrder.executionFee,
        toastMessage,
        orderType.value !== "marketOrder" && true,
        orderType.value, // market / stop loss / take profit
        )
      ;

      successToast(toastMessage);
      setOrderMessage(toastMessage);
      //This URL needs to be updated according to the Kava Explorer.
      setExplorerURL(`https://coston2-explorer.flare.network/tx/${res.hash}`);
      setCompleteTransactionModal(true);
    } catch (e) {
      handleError(e);
    }
    setLoading(false);
  };

  let itemsPerPage = 10;
  const pageCount = Math.ceil(tableData?.allPositions?.length / itemsPerPage);
  const startIndex = currentPage * itemsPerPage;
  const currentItems = tableData?.allPositions?.slice(startIndex, startIndex + itemsPerPage);

  const handlePageClick = (selectedPage) => {
    if (selectedPage >= 0 && selectedPage < pageCount) {
      setCurrentPage(selectedPage);
    }
  };


  return (
    <>
      <div className="trading-section history_table_section">
        {tableData?.allPositions?.length === 0 ? (
          <div className="d-flex align-items-center justify-content-center empty_div w-100">
            <span>You haven't opened any positions yet.</span>
          </div>
        ) : (
          <table className="w-100">
            <thead>
            <tr className="heading swap-content-wrapper">
              {positionColumnTitles.map((column, i) => (
                <th key={i}>{column}</th>
              ))}
            </tr>
            </thead>

            <tbody className="trade-order-wrapper">
            {currentItems?.map((order, i) => (
              <tr className="swap-data data mb-0" key={i}>
                <td>
                  <div className="d-flex align-items-center gap-2">
                    {/*<img src={tokenImages[order.indexAsset].image} height={20} width={20} />*/}
                    <WithTag
                      isInput={false}
                      tag={order.side === 0 ? "long" : "short"}
                      className={order.side === 0 ? "long" : "short"}
                    />
                    <span className="fw-600">{order.indexAsset}/USD</span>

                  </div>
                </td>
                <td>
                  <span>${parseFloat(Number(order.sizeChangeValue).toFixed(2))}</span>
                </td>
                <td>
                  <span>${parseFloat(Number(order.netValue).toFixed(2))}</span>
                </td>
                <td>
                  <span>${parseFloat(Number(order.marketPrice).toFixed(3))}</span>
                </td>
                <td>
                  <span>${parseFloat(Number(order.priceTrigger).toFixed(3))}</span>
                </td>
                <td className={`${order.liquidationPrice < 0 && "loss"}`}>
                  <span> ${parseFloat(Math.abs(order.liquidationPrice).toFixed(2))}</span>
                </td>
                <td>
                  <span>{order.collateralAsset}</span>
                </td>
                <td className="d-flex align-items-center gap-2">
                  <PlButton
                    onClick={() => handleShowWithdraw(order)}
                    label={<DownArrowIcon/>}
                    className="action-button"
                  />
                  <PlButton
                    onClick={() => handleShowDeposit(order)}
                    label={<UpArrowIcon/>}
                    className="action-button"
                  />
                  <PlButton
                    label={<CloseIcon/>}
                    className="action-button"
                    onClick={() => handleShowCloseModal(order)}
                  />
                </td>
              </tr>
            ))}
            </tbody>
          </table>
        )}
        {tableData.allPositions?.length > itemsPerPage &&
        <div className="pagination">
          <CustomPagination pageCount={pageCount} onPageChange={handlePageClick} currentPage={currentPage} />
        </div>
        }
      </div>

      {showCloseModal && (
        <CloseConfirmation
          show={showCloseModal}
          onHide={() => setShowCloseModal(false)}
          closeOrder={closeOrder}
          setCloseOrder={setCloseOrder}
          setClosingSize={setClosingSize}
          closingSize={closingSize}
          orderType={orderType}
          setOrderType={setOrderType}
          reduceType={reduceType}
          setReduceType={setReduceType}
          handleCloseOrder={handleCloseOrder}
        />
      )}

      {showWithdrawModal && (
        <Withdraw
          show={showWithdrawModal}
          onHide={() => setShowWithdrawModal(false)}
          withdrawOrder={withdrawOrder}
          setWithdrawOrder={setWithdrawOrder}
          withdrawAmount={withdrawAmount}
          setWithdrawAmount={setWithdrawAmount}
          handleWithdraw={handleWithdraw}
        />
      )}

      {showDepositModal && (
        <Deposit
          show={showDepositModal}
          onHide={() => setShowDepositModal(false)}
          depositOrder={depositOrder}
          setDepositOrder={setDepositOrder}
          depositAmount={depositAmount}
          setDepositAmount={setDepositAmount}
          handleDeposit={handleDeposit}
          handleApprove={handleApprove}
        />
      )}

    </>
  );
};

export default PositionsTab;
