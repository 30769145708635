// Will mostly have only UI component imports and placement in this file.

import { useEffect, useState } from "react";
import { Col, Tab, Tabs } from "react-bootstrap";
import { useCommonStateContext } from "../../../hooks/commonStateContext";

import { supportedTokens } from "../../../components/static/SupportedTokens";
import PlButton from "../../../components/buttons/Button";
import Confirm from "../../../components/modals/Confirm";
import CompleteTransaction from "../../../components/modals/CompleteTransaction";
import { PercentageButtons } from "../../../components/buttons/Percentage";
import { SlippageRow } from "../../../components/SlippageRow";
import WithTagSelect from "../../../components/inputFields/WithTagSelect";
import WithTag from "../../../components/inputFields/WithTag";

import {
  addLiquidity,
  approve,
  getMasterPoolInfo,
  getMasterUserInfo,
  getUserData,
  poolApprove,
  removeLiquidity,
  trancheClaim,
} from "../../../io/kava";
import configs from "../../../config.json";
import { waitingToast } from "../../../components/toasts/Waiting";
import { successToast } from "../../../components/toasts/Success";
import { handleError } from "../../../components/toasts/Error";
import { useMetaMask } from "metamask-react";
import { ReactComponent as InfoTag } from "../../../assets/images/info_outline.svg";
import { toolTipData } from "../../../components/static/ToolTipData";
import { formatEther, formatUnits, parseEther, parseUnits } from "ethers/lib/utils";
import { compareBigNums, handleDecimals } from "../../../components/HandleDecimals";
import ReactTooltip from "react-tooltip";
import config from "../../../config.json";
import { useWeb3ConnectContext } from "../../../hooks/web3ConnectContext";
import { calcAddLiquidity, calcRemoveLiquidity } from "../../../components/CalcOutPut";

const BuySell = ({
  trancheType,
  trancheConditions,
  setTrancheConditions,
  isSwitchOn,
  setIsSwitchOn,
  setUserInfo,
  setPoolInfo,
  fetchTrancheConditions,
}) => {
  const [confirmLiquidityModal, setConfirmLiquidityModal] = useState(false); // to show and hide confirmation  modal on buy/sell
  const [amountIn, setAmountIn] = useState(""); //user input the amount to add liquidity
  const [amountOut, setAmountOut] = useState(""); //user get tranche amount according amountIn and selected token
  const [minimumReceived, setMinimumReceived] = useState(""); // user received amount according amountOut and slippage
  const [feeAmount, setFeeAmount] = useState(0);
  const { account } = useMetaMask();
  const { accountBalance } = useWeb3ConnectContext();
  const {
    setLoading,
    setUsdValue,
    showConfirmModal,
    setShowConfirmModal,
    setExplorerURL,
    calcSwapOutDetails,
    setUserData,
    userData,
  } = useCommonStateContext();

  const handleShowModal = async () => {
    setConfirmLiquidityModal(true);
    let getFrom;
    let getTo;
    if (trancheConditions.selectedTrancheTab === "buy") {
      const fromPrice = calcSwapOutDetails.prices[trancheConditions.selectedToken.value];
      getFrom = Number(fromPrice) * Number(amountIn);
      getTo = Number(amountOut) * trancheConditions.tranchePrice;
    } else {
      getFrom = Number(amountIn) * trancheConditions.tranchePrice;
      const toPrice = calcSwapOutDetails.prices[trancheConditions.selectedToken.value];
      getTo = Number(toPrice) * Number(amountOut);
    }
    setUsdValue({
      fromUsdValue: parseFloat(Number(getFrom).toFixed(2)),
      toUsdValue: parseFloat(Number(getTo).toFixed(2)),
    });
  };

  useEffect(() => {
    setAmountOut("");
    setAmountIn("");
  }, [trancheConditions.selectedTrancheTab]);

  useEffect(() => {
    if (account !== null && calcSwapOutDetails !== undefined) {
      // for get llp value according input asset
      const countAmountOut = () => {
        let calLpAmount;
        if (trancheConditions.selectedTrancheTab === "buy") {
          calLpAmount = calcAddLiquidity(
            trancheType,
            trancheConditions.selectedToken.value,
            Number(amountIn),
            calcSwapOutDetails
          );
        } else {
          calLpAmount = calcRemoveLiquidity(
            trancheType,
            trancheConditions.selectedToken.value,
            Number(amountIn),
            calcSwapOutDetails
          );
        }
        let tokenType = trancheConditions.selectedToken.value;
        // console.log('addLiquidity:amountIn-', amountIn)
        // console.log('addLiquidity:tokenType-', tokenType)
        let val = handleDecimals(amountIn, config.decimals[tokenType]);
        // console.log("addLiquidity:amountIn-", val);
        // console.log("addLiquidity:lpAmount-", calLpAmount.lpAmount);
        // console.log("addLiquidity:feeRate-", calLpAmount.feeRate);
        let outAmount;
        if (Number(calLpAmount.feeRate) > 0) {
          //outAmount = calLpAmount.lpAmount * (1 - calLpAmount.feeRate);
          outAmount = calLpAmount.lpAmount;
          setFeeAmount(calLpAmount.feeRate * 100);
        } else {
          setFeeAmount(0);
          outAmount = calLpAmount.lpAmount;
        }

        if (outAmount > 0) {
          setAmountOut(outAmount);
        } else {
          setAmountOut("");
        }
      };
      countAmountOut();
    }
  }, [trancheConditions.selectedToken, amountIn, account, calcSwapOutDetails, userData]);

  //for slippage count according selected marketSlippage and amountOut
  useEffect(() => {
    const slippageCount = Number(amountOut) - (Number(amountOut) * Number(trancheConditions.slippage.value)) / 100;
    setMinimumReceived(slippageCount > 0 ? slippageCount : "");
  }, [amountOut, trancheConditions.slippage]);

  //for approve amount of selected asset(from) or selected tranche
  const handleTrancheApprove = async () => {
    setLoading(true);
    try {
      // waitingToast(
      //   `Approving ${
      //     trancheConditions.selectedTrancheTab === "buy"
      //       ? trancheConditions.selectedToken.value
      //       : `${trancheType === "senior" ? "Senior" : trancheType === "junior" ? "Junior" : "Mezzanine"} ALP.`
      //   }`
      // );

      {
        trancheConditions.selectedTrancheTab === "buy"
          ? await approve(
              trancheConditions.selectedToken.value,
              isSwitchOn ? configs.trade.lampMaster : configs.trade.liquidityRouter,
              amountIn
            )
          : await poolApprove(
              trancheType,
              isSwitchOn ? configs.trade.lampMaster : configs.trade.liquidityRouter,
              amountIn
            );
      }

      successToast(
        `${trancheConditions.selectedTrancheTab === "buy"
            ? trancheConditions.selectedToken.value.toFixed(5)
            : `${trancheType === "senior" ? "Senior" : trancheType === "junior" ? "Junior" : "Mezzanine"} ALP`
        } approved.`
      );

      const userDetails = await getUserData(account);
      setUserData(userDetails);
    } catch (e) {
      handleError(e);
    }
    setLoading(false);
  };

  //to execute add or remove liquidity
  const handleBuySell = async () => {
    console.log("trancheTypeInBuy---", trancheType);
    setLoading(true);
    try {
      // waitingToast(
      //   trancheConditions.selectedTrancheTab === "buy"
      //     ? `Buying ${parseFloat(Number(amountOut).toFixed(5))} ${
      //         trancheType === "senior" ? "Senior" : trancheType === "junior" ? "Junior" : "Mezzanine"
      //       } ALP for ${parseFloat(Number(amountIn).toFixed(5))} ${trancheConditions.selectedToken.value}.`
      //     : `Selling ${parseFloat(Number(amountIn).toFixed(5))} ${
      //         trancheType === "senior" ? "Senior" : trancheType === "junior" ? "Junior" : "Mezzanine"
      //       } ALP for ${parseFloat(Number(amountOut).toFixed(5))} ${trancheConditions.selectedToken.value}.`
      //   /*
      //     ? `Selling ${parseFloat(Number(amountIn).toFixed(5))} ${
      //       trancheConditions.selectedToken.value
      //     } to get ${parseFloat(Number(amountOut).toFixed(5))} ${
      //       trancheType === "senior" ? "Senior" : trancheType === "junior" ? "Junior" : "Mezzanine"
      //     } ALP`
      //     : `Selling ${parseFloat(Number(amountIn).toFixed(5))} ${
      //       trancheType === "senior" ? "Senior" : trancheType === "junior" ? "Junior" : "Mezzanine"
      //     } ALP to get ${parseFloat(Number(amountOut).toFixed(5))} ${trancheConditions.selectedToken.value}`,
      //     */
      // );

      let res;
      if (trancheConditions.selectedTrancheTab === "buy") {
        res = await addLiquidity(
          trancheType,
          trancheConditions.selectedToken.value,
          amountIn,
          minimumReceived,
          account,
          amountOut,
          isSwitchOn
        );
      } else {
        res = await removeLiquidity(
          trancheType,
          trancheConditions.selectedToken.value,
          amountIn,
          minimumReceived,
          account,
          amountOut,
          isSwitchOn
        );
      }

      successToast(
        trancheConditions.selectedTrancheTab === "buy"
          ? `Bought ${parseFloat(Number(amountOut).toFixed(5))} ${
              trancheType === "senior" ? "Senior" : trancheType === "junior" ? "Junior" : "Mezzanine"
            } ALP for ${parseFloat(Number(amountIn).toFixed(5))} ${trancheConditions.selectedToken.value}.`
          : `Sold ${parseFloat(Number(amountIn).toFixed(5))} ${
              trancheType === "senior" ? "Senior" : trancheType === "junior" ? "Junior" : "Mezzanine"
            } ALP for ${parseFloat(Number(amountOut).toFixed(5))} ${trancheConditions.selectedToken.value}.`
        /*
          ? `Selling ${parseFloat(Number(amountIn).toFixed(5))} ${
            trancheConditions.selectedToken.value
          } to get ${parseFloat(Number(amountOut).toFixed(5))} ${
            trancheType === "senior" ? "Senior" : trancheType === "junior" ? "Junior" : "Mezzanine"
          } ALP`
          : `Selling ${parseFloat(Number(amountIn).toFixed(5))} ${
            trancheType === "senior" ? "Senior" : trancheType === "junior" ? "Junior" : "Mezzanine"
          } ALP to get ${parseFloat(Number(amountOut).toFixed(5))} ${trancheConditions.selectedToken.value}`,
          */
      );

      //This URL needs to be updated according to the Kava Explorer.
      setExplorerURL(`https://coston2-explorer.flare.network/tx/${res.hash}`);
      fetchTrancheConditions();
      setShowConfirmModal(true);
    } catch (e) {
      handleError(e);
    }
    setLoading(false);
  };

  const handleClaim = async () => {
    setLoading(true);
    try {
      // waitingToast(`Claiming reward.`);
      const res = await trancheClaim(trancheType === "senior" ? 0 : trancheType === "mezzanine" ? 1 : 2, account);

      const masterPoolInfo = await getMasterPoolInfo(trancheType === "senior" ? 0 : trancheType === "junior" ? 2 : 1);
      setPoolInfo(masterPoolInfo);
      const masterUserInfo = await getMasterUserInfo(
        trancheType === "senior" ? 0 : trancheType === "junior" ? 2 : 1,
        account
      );
      setUserInfo(masterUserInfo);
      successToast(`Aipx claimed.`);

      const userDetails = await getUserData(account);
      setUserData(userDetails);

      successToast(`Reward claimed.`);
    } catch (e) {
      handleError(e);
    }

    setLoading(false);
  };
  // console.log("trancheConditions.stakingBalance-", trancheConditions.stakingBalance);
  // console.log("amountIn-", amountIn);

  const handleCloseSuccessModal = () => {
    setAmountOut("");
    setAmountIn("");
    setShowConfirmModal(false);
    //fetchTrancheConditions();
  };

  return (
    <>
      <Col className="mt-1" md={4}>
        <p className="unclaim_rewards_text d-flex justify-content-between align-items-center mb-4">
          Unclaimed Rewards:{" "}
          {trancheConditions.unClaimRewards !== undefined
            ? (Number(trancheConditions?.unClaimRewards) < 0.01 && Number(trancheConditions?.unClaimRewards) > 0)
              ? "<0.01"
              : parseFloat(Number(trancheConditions.unClaimRewards).toFixed(2))
            : "-"}{" "}
          AIPX{" "}
          <span
            className={`m-0 card_desc_tag ${
              accountBalance === undefined || Number(trancheConditions.unClaimRewards) <= 0 ? "disabled" : undefined
            }`}
            onClick={handleClaim}
          >
            CLAIM
          </span>
        </p>
        <div className="shadowed-box">
          <Tabs
            onSelect={(k) => setTrancheConditions({ ...trancheConditions, selectedTrancheTab: k })}
            activeKey={trancheConditions.selectedTrancheTab}
            id="pool-tab"
            // className="mb-3 earn-details-tabs pool_tabs"
            className="mb-3 swap_card_tabs trade_tabs"
          >
            <Tab eventKey="buy" title="BUY">
              <div className="mt-4">
                <div className="d-flex align-items-center justify-content-center gap-3 mb-4">
                  <span className={`switch_text ${!isSwitchOn ? "fw-600" : undefined}`}>Buy Into Wallet</span>
                  <label className="switch">
                    <input
                      type="checkbox"
                      id="check_id"
                      checked={isSwitchOn}
                      onChange={() => setIsSwitchOn(!isSwitchOn)}
                    />
                    <span className="slider round"></span>
                  </label>
                  <span className={`switch_text ${isSwitchOn ? "fw-600" : undefined}`}>Buy & Stake</span>
                </div>
                <WithTagSelect
                  label={"Amount"}
                  placeholder={"0.0"}
                  value={trancheConditions.selectedToken}
                  inputValue={amountIn}
                  onChange={(e) => setAmountIn(e.target.value)}
                  onSelectChange={(val) => setTrancheConditions({ ...trancheConditions, selectedToken: val })}
                  optionValue={supportedTokens(userData?.balances)}
                  className={Number(amountIn) > trancheConditions.tokenBalance ? "loss" : undefined}
                  // usdValue={parseFloat((Number(amountIn) * calcSwapOutDetails?.prices[trancheConditions.selectedToken.value]).toFixed(2))}
                  usdValue={
                    0 < Number(amountIn) * calcSwapOutDetails?.prices[trancheConditions.selectedToken.value] &&
                    Number(amountIn) * calcSwapOutDetails?.prices[trancheConditions.selectedToken.value] < 0.01
                      ? "<0.01"
                      : parseFloat(
                          (
                            Number(amountIn) * calcSwapOutDetails?.prices[trancheConditions.selectedToken.value]
                          ).toFixed(2)
                        )
                  }
                  selectClassName={"token-select"}
                />
                <div className="mt-2 token_balance">
                  <span className="balance">
                    BAL:{" "}
                    {trancheConditions?.tokenBalance
                      ? parseFloat(Number(trancheConditions?.tokenBalance)?.toFixed(5))
                      : "-"}{" "}
                  </span>
                  <PercentageButtons balance={trancheConditions.tokenBalance} setAmountIn={setAmountIn} />
                </div>
              </div>
              <div className="mt-4">
                <WithTag
                  label={"Receive"}
                  inputClass={"cursor_not_allowed profit"}
                  placeholder={"0.0"}
                  inputValue={amountOut}
                  readOnly={true}
                  tag={
                    <div className="d-flex align-items-center gap-1">
                      <div className="lamp_tag eth_lamp"></div>
                      {trancheType.toUpperCase()} ALP
                    </div>
                  }
                  // subValue={parseFloat((Number(amountOut) * trancheConditions?.tranchePrice).toFixed(2))}
                  subValue={
                    0 < Number(amountOut) * trancheConditions?.tranchePrice &&
                    Number(amountOut) * trancheConditions?.tranchePrice < 0.01
                      ? "<0.01"
                      : parseFloat((Number(amountOut) * trancheConditions?.tranchePrice).toFixed(2))
                  }
                />
              </div>

              <div className="mt-4">
                <ReactTooltip
                  backgroundColor="black"
                  className="react_tooltip"
                  arrowColor="black"
                  place="top"
                  type="dark"
                  effect="solid"
                />
                <p className="swap_card_details_p d-flex justify-content-between align-items-center">
                  <span className="d-flex align-items-center gap-1">
                    Minimum Received <InfoTag className="info_icon" data-tip={toolTipData.minimumReceived} />
                  </span>
                  <span className="fw-600">
                    {Number(minimumReceived) > 0
                      ? `${Number(minimumReceived) < 0.01 ? "<0.01" : parseFloat(minimumReceived?.toFixed(2))} ALP`
                      : "-"}
                  </span>
                </p>
                <p className="swap_card_details_p d-flex justify-content-between align-items-center">
                  Weight/Target{" "}
                  <span className="fw-600">
                    {
                      //(trancheConditions.weightValue && trancheConditions.targetValue) ?
                      trancheConditions.targetValue
                        ? `${trancheConditions.weightValue?.toFixed(2)}%/${trancheConditions.targetValue}%`
                        : "-"
                    }
                  </span>
                </p>
                <p className="swap_card_details_p d-flex justify-content-between align-items-center">
                  Fees <span className="fw-600">{Number(amountOut) > 0 ? `${feeAmount.toFixed(2)}%` : "-"}</span>
                </p>{" "}
                <SlippageRow allDetails={trancheConditions} setAllDetails={setTrancheConditions} />
              </div>

              <div className="mt-4">
                <PlButton
                  disabled={accountBalance === undefined || Number(amountIn) === 0 || compareBigNums(amountIn,  trancheConditions.tokenBalance)}
                  label={accountBalance === undefined
                    ? `buy ${trancheType} ALP`
                    : Number(amountIn) === 0
                      ? "enter an amount"
                      : compareBigNums(amountIn,  trancheConditions.tokenBalance)
                        ? "insufficient funds"
                        : trancheConditions.approvedTokenAmount < Number(amountIn)
                          ? "approve"
                          : `buy ${trancheType} ALP`
                  }
                  onClick={() =>
                    trancheConditions.approvedTokenAmount < Number(amountIn)
                      ? handleTrancheApprove()
                      : handleShowModal()
                  }
                />
              </div>
            </Tab>
            <Tab eventKey="sell" title="SELL">
              <div className="mt-4">
                <div className="d-flex align-items-center justify-content-center gap-3 mb-4">
                  <span className={`switch_text ${!isSwitchOn ? "fw-600" : undefined}`}>Sell From Wallet</span>
                  <label className="switch">
                    <input
                      type="checkbox"
                      id="check_id"
                      checked={isSwitchOn}
                      onChange={() => setIsSwitchOn(!isSwitchOn)}
                    />
                    <span className="slider round"></span>
                  </label>
                  <span className={`switch_text ${isSwitchOn ? "fw-600" : undefined}`}>Withdraw & Sell</span>
                </div>
                <WithTag
                  inputClass={
                    isSwitchOn
                      ? Number(amountIn) > Number(trancheConditions.stakingBalance)
                        ? "loss"
                        : undefined
                      : Number(amountIn) > Number(trancheConditions.trancheBalance)
                      ? "loss"
                      : undefined
                  }
                  label={"Amount"}
                  placeholder={"0.0"}
                  inputValue={amountIn}
                  onChange={(e) => setAmountIn(e.target.value)}
                  tag={
                    <div className="d-flex align-items-center gap-1">
                      <div className="lamp_tag eth_lamp"></div>
                      {trancheType.toUpperCase()} ALP
                    </div>
                  }
                  // subValue={parseFloat((Number(amountIn) * trancheConditions?.tranchePrice).toFixed(2))}
                  subValue={
                    0 < Number(amountIn) * trancheConditions?.tranchePrice &&
                    Number(amountIn) * trancheConditions?.tranchePrice < 0.01
                      ? "<0.01"
                      : parseFloat((Number(amountIn) * trancheConditions?.tranchePrice).toFixed(2))
                  }
                />
                <div className="mt-2 token_balance">
                  <span className="balance">
                    {isSwitchOn ? "Staking" : "Wallet"} BAL:{" "}
                    {isSwitchOn
                      ? trancheConditions.stakingBalance
                        ? parseFloat(Number(trancheConditions.stakingBalance).toFixed(5))
                        : "-"
                      : trancheConditions.trancheBalance
                      ? parseFloat(Number(trancheConditions.trancheBalance).toFixed(5))
                      : "-"}{" "}
                  </span>
                  <PercentageButtons
                    setAmountIn={setAmountIn}
                    balance={isSwitchOn ? trancheConditions.stakingBalance : trancheConditions.trancheBalance}
                  />
                </div>
              </div>
              <div className="mt-4">
                <WithTagSelect
                  label={"Receive"}
                  placeholder={"0.0"}
                  value={trancheConditions.selectedToken}
                  readOnly={true}
                  inputValue={amountOut}
                  onSelectChange={(val) => setTrancheConditions({ ...trancheConditions, selectedToken: val })}
                  optionValue={supportedTokens(userData?.balances)}
                  className={`cursor_not_allowed ${
                    Number(amountOut) > Number(trancheConditions.tokenFund) ? "loss" : "profit"
                  }`}
                  // usdValue={parseFloat((Number(amountOut) * calcSwapOutDetails?.prices[trancheConditions.selectedToken.value]).toFixed(2))}
                  usdValue={
                    0 < Number(amountOut) * calcSwapOutDetails?.prices[trancheConditions.selectedToken.value] &&
                    Number(amountOut) * calcSwapOutDetails?.prices[trancheConditions.selectedToken.value] < 0.01
                      ? "<0.01"
                      : parseFloat(
                          (
                            Number(amountOut) * calcSwapOutDetails?.prices[trancheConditions.selectedToken.value]
                          ).toFixed(2)
                        )
                  }
                  selectClassName={"token-select hide_balance"}
                />
              </div>

              <div className="mt-4">
                <ReactTooltip
                  backgroundColor="black"
                  className="react_tooltip"
                  arrowColor="black"
                  place="top"
                  type="dark"
                  effect="solid"
                />
                <p className="swap_card_details_p d-flex justify-content-between align-items-center mb-3">
                  <span className="d-flex align-items-center gap-1">
                    Minimum Received <InfoTag className="info_icon" data-tip={toolTipData.minimumReceived} />
                  </span>

                  <span className="fw-600">
                    {Number(minimumReceived) > 0
                      ? `${Number(minimumReceived) < 0.01 ? "<0.01" : parseFloat(minimumReceived?.toFixed(2))} ${
                          trancheConditions.selectedToken.value
                        }`
                      : "-"}
                  </span>
                </p>
                <p className="swap_card_details_p d-flex justify-content-between align-items-center mb-3">
                  Available Fund{" "}
                  <span className="fw-600">
                    {trancheConditions.tokenFund ? parseFloat(trancheConditions.tokenFund?.toFixed(5)) : "-"}{" "}
                    {trancheConditions.selectedToken.value}
                  </span>
                </p>
                <p className="swap_card_details_p d-flex justify-content-between align-items-center mb-3">
                  Weight/Target{" "}
                  <span className="fw-600">
                    {
                      //(trancheConditions.weightValue && trancheConditions.targetValue)
                      trancheConditions.targetValue
                        ? `${trancheConditions.weightValue?.toFixed(2)}%/${trancheConditions.targetValue}%`
                        : "-"
                    }
                  </span>
                </p>
                <p className="swap_card_details_p d-flex justify-content-between align-items-center mb-3">
                  Fees <span className="fw-600">{Number(amountIn) > 0 ? `${feeAmount.toFixed(2)}%` : "-"}</span>
                </p>
                <SlippageRow allDetails={trancheConditions} setAllDetails={setTrancheConditions} />
              </div>

              <div className="mt-4">
                <PlButton
                  disabled={
                    accountBalance === undefined ||
                    Number(amountIn) === 0 ||
                    Number(amountOut) <= 0 ||
                    compareBigNums(amountOut, trancheConditions.tokenFund) ||
                    compareBigNums(
                      amountIn,
                      isSwitchOn ? trancheConditions.stakingBalance : trancheConditions?.trancheBalance
                    )
                  }
                  label={accountBalance === undefined
                    ? `sell ${trancheType} ALP`
                    : Number(amountIn) === 0
                      ? "enter an amount"
                      : (Number(amountOut) <= 0 || compareBigNums(amountOut, trancheConditions.tokenFund))
                        ? "insufficient liquidity"
                        : compareBigNums(amountIn, isSwitchOn ? trancheConditions.stakingBalance : trancheConditions?.trancheBalance)
                          ? "insufficient funds"
                          : trancheConditions.approvedTrancheAmount < Number(amountIn) && !isSwitchOn
                            ? "approve"
                            : `sell ${trancheType} ALP`
                  }
                  onClick={() =>
                    trancheConditions.approvedTrancheAmount < Number(amountIn) && !isSwitchOn
                      ? handleTrancheApprove()
                      : handleShowModal()
                  }
                />
              </div>
            </Tab>
          </Tabs>
        </div>
      </Col>

      {confirmLiquidityModal && (
        <Confirm
          title={`CONFIRM ${trancheConditions.selectedTrancheTab.toUpperCase()} LIQUIDITY`}
          show={confirmLiquidityModal}
          trancheType={trancheType}
          onHide={() => setConfirmLiquidityModal(false)}
          modalDetails={{ ...trancheConditions, trancheFee: feeAmount }}
          amountIn={amountIn}
          amountOut={amountOut}
          minimumReceived={minimumReceived}
          handleBuySell={handleBuySell}
        />
      )}

      {showConfirmModal && (
        <CompleteTransaction
          show={showConfirmModal}
          onHide={handleCloseSuccessModal}
          trancheType={trancheType}
          trancheConditions={trancheConditions}
          amountIn={amountIn}
          amountOut={amountOut}
        />
      )}
    </>
  );
};

export default BuySell;
