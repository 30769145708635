import { slippageOptions } from "./static/SlippageOptions";
import WithTagSelect from "./inputFields/WithTagSelect";
import {ReactComponent as InfoTag } from '../assets/images/info_outline.svg';
import ReactTooltip from 'react-tooltip';
import { toolTipData } from "./static/ToolTipData";

export const SlippageRow = ({ allDetails, setAllDetails }) => {
  return (
    <>
      <ReactTooltip backgroundColor='black' className='react_tooltip' arrowColor='black' place="top" type="dark" effect="solid" />
      <div className="swap_card_details_p d-flex justify-content-between align-items-center">
        <span>Slippage <InfoTag className='info_icon' data-tip={toolTipData.slippage}/></span>
        <span>
          <WithTagSelect
            isInput={false}
            value={allDetails.slippage}
            onSelectChange={(val) => setAllDetails({ ...allDetails, slippage: val })}
            optionValue={slippageOptions}
            selectClassName={'slippage_tag'}
            className={'fw-normal'}
            hideSelectedOptions={true}
          />
        </span>
      </div>
    </>
  );
};