import React from "react";
import { LineChart, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Line, Legend } from "recharts";

const Chart = () => {
  const data = [
    {
      name: "02/01",
      AUM: 16,
      "LLP Price": 12,
      BTC: 15,
      ETH: 10,
      USDT: 12,
      BNB: 11,
    },
    {
      name: "09/01",
      AUM: 12,
      "LLP Price": 20,
      BTC: 17,
      ETH: 14,
      USDT: 16,
      BNB: 19,
    },
    {
      name: "15/01",
      AUM: 10,
      "LLP Price": 18,
      BTC: 25,
      ETH: 20,
      USDT: 22,
      BNB: 21,
    },
    {
      name: "23/01",
      AUM: 19,
      "LLP Price": 26,
      BTC: 22,
      ETH: 27,
      USDT: 19,
      BNB: 30,
    },
    {
      name: "01/02",
      AUM: 27,
      "LLP Price": 22,
      BTC: 31,
      ETH: 22,
      USDT: 25,
      BNB: 27,
    },
    {
      name: "11/02",
      AUM: 24,
      "LLP Price": 20,
      BTC: 30,
      ETH: 28,
      USDT: 21,
      BNB: 25,
    },
    {
      name: "20/02",
      AUM: 30,
      "LLP Price": 25,
      BTC: 25,
      ETH: 20,
      USDT: 24,
      BNB: 23,
    },
    {
      name: "28/02",
      AUM: 22,
      "LLP Price": 31,
      BTC: 19,
      ETH: 15,
      USDT: 28,
      BNB: 27,
    },
    {
      name: "09/03",
      AUM: 31,
      "LLP Price": 26,
      BTC: 21,
      ETH: 27,
      USDT: 12,
      BNB: 11,
    },
    {
      name: "17/03",
      AUM: 25,
      "LLP Price": 20,
      BTC: 22,
      ETH: 21,
      USDT: 18,
      BNB: 14,
    },
    {
      name: "31/03",
      AUM: 28,
      "LLP Price": 30,
      BTC: 27,
      ETH: 15,
      USDT: 22,
      BNB: 19,
    },
  ];

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label mb-1">{payload[0].payload.withYear}</p>
          <p className="desc mb-0">LLP price: {payload[0].payload["LLP price"]}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <>
      <div className="mb-4 shadowed-box px-0">
        <div className="chart-wrapper pool_chart">
          <div className="d-flex justify-content-between align-items-center mb-3 chart-title ps-4">
            <h2 className="mb-0">LLP Price</h2>
          </div>
          <ResponsiveContainer width="100%" height="90%">
            <LineChart
              width={500}
              height={300}
              data={data}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeOpacity="1.5" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip content={<CustomTooltip />} />
              <Legend />
              <Line type="monotone" strokeWidth="3px" dataKey="LLP Price" stroke="#FFD000" dot={null} />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
      <div className="mb-4 shadowed-box px-0">
        <div className="chart-wrapper pool_chart">
          <div className="d-flex justify-content-between align-items-center mb-3 chart-title ps-4">
            <h2 className="mb-0">AUM & LLP Supply</h2>
          </div>
          <ResponsiveContainer width="100%" height="90%">
            <LineChart
              width={500}
              height={300}
              data={data}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeOpacity="0.5" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" strokeWidth="3px" dataKey="LLP Price" stroke="#FFD000" dot={null} />
              <Line type="monotone" strokeWidth="3px" dataKey="AUM" stroke="#FF6600" dot={null} />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
      <div className=" shadowed-box px-0">
        <div className="chart-wrapper pool_chart">
          <div className="d-flex justify-content-between align-items-center mb-3 chart-title ps-4">
            <h2 className="mb-0">Token Distribution</h2>
          </div>
          <ResponsiveContainer width="100%" height="90%">
            <LineChart
              width={500}
              height={300}
              data={data}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeOpacity="0.5" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="BTC" stroke="#F7931A" className="BTC" dot={null} />
              <Line type="monotone" dataKey="ETH" stroke="#627EEA" className="ETH" dot={null} />
              <Line type="monotone" dataKey="USDT" stroke="#F3BA2F" className="USDT" dot={null} />
              <Line type="monotone" dataKey="FLR" stroke="#26A17B" className="FLR" dot={null} />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </>
  );
};

export default Chart;
