const WithTag = ({
  label,
  isInput = true,
  placeholder,
  subValue,
  disabled,
  onChange,
  inputValue,
  tag,
  className,
  inputClass,
  readOnly
}) => {
  return (
    <>
      {label && <label className='input_label'>{label}</label>}
      <div className={`tag-select ${isInput ? "input-wrapper":undefined} ${disabled ? "disabled":undefined} fixed-tag-input`}>
        <div className='USD_wrapper'>
          {isInput && (
            <input
              type="number"
              value={inputValue}
              min="0"
              onKeyPress={(event) => {
                if (event.charCode < 48 && event.charCode !== 46) {
                  event.preventDefault();
                }
              }}
              className={inputClass}
              placeholder={placeholder}
              onChange={onChange}
              disabled={disabled}
              readOnly={readOnly}
            />
          )}
          {(subValue > 0 || typeof subValue === "string") && <p className="my-0">~ ${subValue}</p>}
        </div>
        <span className={`fixed-tag ${className}`}>{tag}</span>
      </div>
    </>
  );
};

export default WithTag;
