import { Card, Col } from "react-bootstrap";

import arrowUp from "../../../assets/images/arrow_drop_down_green.svg";
import arrowDown from "../../../assets/images/arrow_drop_down_red.svg";

import NoBoxSelect from "../../../components/inputFields/NoBoxSelect";
import { posSizeTokens, TradeChartTokens } from "../../../components/static/SupportedTokens";
import React, { useEffect, useRef } from "react";

let tvScriptLoadingPromise;

const TokenPairActivity = ({ tradeConditions, setTradeConditions, tradingDetails }) => {
  const onLoadScriptRef = useRef();

  const data = {
    BTC: { symbol: "BTCUSD",market:"BINANCE" },
    FLR: { symbol: "FLRUSD",market:"COINBASE" },
    ETH: { symbol: "ETHUSD",market:"BINANCE" },
  };

  useEffect(() => {
    onLoadScriptRef.current = createWidget;

    if (!tvScriptLoadingPromise) {
      tvScriptLoadingPromise = new Promise((resolve) => {
        const script = document.createElement("script");
        script.id = "tradingview-widget-loading-script";
        script.src = "https://s3.tradingview.com/tv.js";
        script.type = "text/javascript";
        script.style = { borderRadius: "20" };
        script.onload = resolve;

        document.head.appendChild(script);
      });
    }

    tvScriptLoadingPromise.then(() => onLoadScriptRef.current && onLoadScriptRef.current());

    return () => (onLoadScriptRef.current = null);

    function createWidget() {
      if (document.getElementById("chart") && "TradingView" in window) {
        new window.TradingView.widget({
          autosize: true,
          symbol: `${data[tradeConditions.selectedTo.value].market}:${data[tradeConditions.selectedTo.value].symbol}`,
          interval: "15",
          timezone: "Etc/UTC",
          theme: "dark",
          style: "1",
          locale: "en",
          withdateranges: false,
          enable_publishing: false,
          hide_side_toolbar: true,
          hide_top_toolbar: false,
          backgroundColor: "rgba(37, 41, 39, 1)",
          hide_legend: true,
          allow_symbol_change: true,
          save_image: false,
          container_id: "chart",
        });
      }
    }
  }, [tradeConditions.selectedTo]);


  return (
    <>
      <Col>
        <Card className="shadowed-box trade_head_detail">
          <Card.Body className="py-3 px-0">
            <div className="d-flex justify-content-start align-items-center gap-xxl-4 gap-xl-3">
              <div className="trade-main-wrapper">
                <NoBoxSelect
                  value={TradeChartTokens.find((token) => token.value === tradeConditions.selectedTo.value)}
                  onSelectChange={(val) =>
                    setTradeConditions({
                      ...tradeConditions,
                      selectedTo: posSizeTokens.find((token) => token.value === val.value),
                    })
                  }
                  optionValue={TradeChartTokens}
                />
              </div>
              <div
                className={`trade-card-value ${Object.keys(tradingDetails).length > 0 ?
                  tradingDetails.change > 0 ? "trade-change-value" : "trade-change-minus-value" : ""
                } `}
              >{
                Object.keys(tradingDetails).length > 0
                  ? `$${parseFloat(Number(tradingDetails.price).toFixed(3)).toLocaleString()}`
                  : "-"
              }

              </div>
              <div className="trade-card-content">
                <p>24h Change</p>
                <span className={`${Object.keys(tradingDetails).length > 0 ? tradingDetails.change > 0
                  ? "trade-change-value"
                  : "trade-change-minus-value"
                  : ""}`}>
                  {Object.keys(tradingDetails).length > 0
                    ? <>
                      ${parseFloat(Math.abs(tradingDetails?.change)?.toFixed(2))}
                      <span className="ms-2">
                    <img src={tradingDetails.change > 0 ? arrowUp : arrowDown} />
                  </span>
                      <span className="ms-1">
                      {parseFloat(Math.abs(tradingDetails?.changePercentage)?.toFixed(2))}%
                  </span>
                    </>
                    : "-"
                  }
                </span>
              </div>
              <div className="trade-card-content">
                <p>24h High</p>
                <span>
                  {Object.keys(tradingDetails).length > 0
                    ? `${parseFloat(Number(tradingDetails.high).toFixed(3)).toLocaleString()}`
                    : "-"
                  }
                </span>
              </div>
              <div className="trade-card-content">
                <p>24h Low</p>
                <span>
                    {Object.keys(tradingDetails).length > 0
                      ? `${parseFloat(Number(tradingDetails.low).toFixed(3)).toLocaleString()}`
                      : "-"
                    }
                </span>
              </div>
              <div className="trade-card-content">
                <p>24h Volume ({tradingDetails.token})</p>
                <span>
                    {Object.keys(tradingDetails).length > 0
                      ? `${parseFloat(Number(tradingDetails.volume).toFixed(3)).toLocaleString()}`
                      : "-"
                    }
                </span>
              </div>
              <div className="trade-card-content">
                <p>24h Volume (USD)</p>
                <span>
                   {Object.keys(tradingDetails).length > 0
                     ? `$${Math.round(tradingDetails.usdVolume).toLocaleString()}`
                     : "-"
                   }
                 </span>
              </div>
            </div>
          </Card.Body>
        </Card>
      </Col>
      <div className="shadowed-box mt-0 trading-chart-wrappper">
        <div className="tradingview-widget-container" style={{ height: "100%" }}>
          <div id="chart" style={{ height: "100%" }} />
          <div className="tradingview-widget-copyright">
            <a
              href={`https://www.tradingview.com/symbols/${
                data[tradeConditions.selectedTo.value].symbol
              }/?exchange=${
                data[tradeConditions.selectedTo.value].market
              }`}
              rel="noopener"
              target="_blank"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TokenPairActivity;
