// Will mostly have only UI component imports and placement in this file.

import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import arrowSvg from "../../../assets/images/arrow-forward.svg";

import PlButton from "../../../components/buttons/Button";
import { useTradeTokenContext } from "../../../hooks/tradeTokenContext";
import { tokenImages } from "../../../components/static/SupportedTokens";

const TradingPairs = ({ tradingDetails, tradingData }) => {
  const { setTradeToken } = useTradeTokenContext(); //for set trading pair on trade page
  const navigate = useNavigate();

  return (
    <>
      <div className="trading-section">
        <p className="title">Trading Pairs</p>
        <Row className="heading dashboard_row w-100 mx-0 ps-3">
          {tradingData.columnTitle.map((item, i) => (
            <Col key={i} className={item.className}>
              <p>{item.title}</p>
            </Col>
          ))}
        </Row>
        {tradingDetails?.map((item, i) => (
          <Row className="data shadowed-box dashboard_row py-0 pe-0 ps-3 w-100 mx-0 pointer-cursor"
               onClick={() => {
                 setTradeToken(item.token);
                 navigate("/trade");
               }}
               key={i}>
            <Col className="pl-4">
              <span className="d-flex align-items-center justify-content-start gap-1">
                <img src={tokenImages[item.token].image} height={22} width={22} /> {item.token}/USD
              </span>
            </Col>
            <Col >
              <span className={item.changePercentage < 0 ? "loss" : "profit"}>
                {parseFloat(Number(item.price).toFixed(3))}
              </span>
            </Col>
            <Col>
              <span className={item.changePercentage < 0 ? "loss" : "profit"}>
                {`${(item.changePercentage < 0.01 && item.changePercentage > 0)? '<0.01': item.changePercentage.toFixed(2)}%`}
              </span>
            </Col>
            <Col>
              <span>{(Number(item.high) < 0.01 && Number(item.high) > 0) ? "<0.001" : parseFloat(Number(item.high).toFixed(3)).toLocaleString()}</span>
            </Col>
            <Col>
              <span>{(Number(item.low) < 0.01 && Number(item.low) > 0) ? "<0.001" : parseFloat(Number(item.low).toFixed(3)).toLocaleString()}</span>
            </Col>
            <Col>
              <span>{(Number(item.volume) < 0.01 && Number(item.volume) > 0) ? "<0.01" : parseFloat(Number(item.volume).toFixed(3)).toLocaleString()}</span>
            </Col>
            <Col>
              <span>${(Number(item.usdVolume) < 0.01 && Number(item.usdVolume) > 0) ? "<0.01" : Math.round(item.usdVolume).toLocaleString()}</span>
            </Col>
            <Col className="">
              <PlButton src={arrowSvg} className="arrow-transparent" height={14} width={14} />
            </Col>
          </Row>
        ))}
      </div>
    </>
  );
};

export default TradingPairs;
