import { useState } from "react";

import Deposit from "../modals/Deposit";
import Claim from "../modals/Claim";
import PlButton from "../../../components/buttons/Button";
import { waitingToast } from "../../../components/toasts/Waiting";
import {
  approvedGovernance,
  approvedPlatform,
  governanceApprove,
  governanceClaim,
  governanceDeposit,
  governanceWithdraw,
  platformApprove,
  platformClaim,
  platformDeposit,
  platformWithdraw,
} from "../../../io/kava";
import { successToast } from "../../../components/toasts/Success";
import { handleError } from "../../../components/toasts/Error";
import { useCommonStateContext } from "../../../hooks/commonStateContext";
import { useMetaMask } from "metamask-react";

const TokenInfo = ({ type, getData, data }) => {
  //to set value in modal input
  const [amount, setAmount] = useState("");
  const [depositModal, SetDepositModal] = useState(false);
  const [claimModal, SetClaimModal] = useState(false);
  const { setLoading } = useCommonStateContext();
  const { account } = useMetaMask();

  const handleApprove = async (type) => {
    setLoading(true);
    try {
      // waitingToast(`Approving ${type === "governance" ? "Governance" : "Platform"}.`);

      if (type === "governance") {
        await governanceApprove();
      } else {
        await platformApprove();
      }

      successToast(`${type === "governance" ? "Governance" : "Platform"} Approved.`);
      await getData();
      const amt = type === "governance" ? await approvedGovernance(account) : await approvedPlatform(account);

      SetDepositModal({ ...depositModal, approvedBalance: amt });
    } catch (e) {
      handleError(e);
      setLoading(false);
    }

  };

  const handleDeposit = async (type) => {
    setLoading(true);
    try {
      // waitingToast(`Depositing ${amount} ${type === "governance" ? "Governance" : "Platform"}.`);
      const txn =
        type === "governance" ? await governanceDeposit(account, amount) : await platformDeposit(account, amount);
      console.log("DEPOSIT", txn);
      successToast(`${amount} ${type === "governance" ? "Governance" : "Platform"} deposited.`);
      await getData();
    } catch (e) {
      handleError(e);
      setLoading(false);
    }
  };

  const handleWithdraw = async (type) => {
    setLoading(true);
    try {
      // waitingToast(`Withdrawing ${amount} ${type === "governance" ? "Governance" : "Platform."}`);
      const txn =
        type === "governance" ? await governanceWithdraw(account, amount) : await platformWithdraw(account, amount);
      console.log("WITHDRAW", txn);
      successToast(`${amount} ${type === "governance" ? "Governance" : "Platform"} withdrew.`);
      await getData();
    } catch (e) {
      handleError(e);
      setLoading(false);
    }
  };


  const handleClaim = async (type) => {
    console.log("type--", type);
    setLoading(true);
    try {
      // waitingToast(`Claiming  ${type === "governance" ? "Governance" : "Platform"}.`);
      const txn = type === "governance" ? await governanceClaim(account) : await platformClaim(account);
      console.log("CLAIM", txn);
      successToast(`${type === "governance" ? "Governance" : "Platform"} claimed.`);
      await getData();
    } catch (e) {
      handleError(e);
      setLoading(false);
    }
  };

  return (
    <div className="shadowed-box">
      <div className="d-flex align-items-center">
        <div className="earn-heading"></div>
        <div className="earn-heading-text justify-content-center">
          <p className="mb-0">{type === "governance" ? "Governance" : "Platform"}</p>
        </div>
      </div>

      <div className="mt-4">
        <p className="earn_details_p d-flex justify-content-between align-items-center mb-3">
          Total Deposited <span className="fw-600">{data.deposit} AIPX</span>
        </p>
        <p className="earn_details_p d-flex justify-content-between align-items-center mb-3">
          APR <span className="fw-600">NA %</span>
        </p>
        <p className="earn_details_p d-flex justify-content-between align-items-center mb-3">
          Your Deposit <span className="fw-600 earn-text">{data.userDeposit} AIPX</span>
        </p>
        <p className="earn_details_p d-flex justify-content-between align-items-center mb-3">
          Estimated Earning per day<span className="fw-600">
          {Number(data.userDeposit) === 0 ? 0 : parseFloat(data.reward)} Senior ALP
        </span>
        </p>
        <p className="earn_details_p d-flex justify-content-between align-items-center mb-3">
          Claimable Reward
          <span className="fw-600 earn-text">
          {parseFloat(Number(data.userReward).toFixed(2))} Senior ALP
        </span>
        </p>
        {type === "platform" ?
          <p className="earn_details_p d-flex justify-content-between align-items-center mb-3">
             Staking Fee<span className="fw-600 earn-text">{data.stakingTax}%</span>
          </p>:
          <p className='mb-3' style={{height:'16px'}}></p>
        }
      </div>

      <div className="earn-deposit-button mt-4">
        <PlButton
          label={"Deposit/Withdraw"}
          onClick={() => SetDepositModal({ ...data, type: type })}
        />
      </div>

      <div className="earn-deposit-button mt-4">
        <PlButton
          label={"Claim"}
          className="transparent"
          onClick={() => SetClaimModal({ claimAmount: data.userReward || "0.0", type: type })}
        />
      </div>

      {depositModal && <Deposit
        show={depositModal}
        onHide={() => {
          SetDepositModal(false);
          setAmount("");
        }}
        setAmount={setAmount}
        amount={amount}
        handleDeposit={handleDeposit}
        handleWithdraw={handleWithdraw}
        handleApprove={handleApprove}
      />}

      {claimModal && <Claim show={claimModal} handleClaim={handleClaim} onHide={() => SetClaimModal(false)} />}
    </div>
  );
};

export default TokenInfo;
