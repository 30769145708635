import { Col } from "react-bootstrap";

import PlButton from "../../../components/buttons/Button";
import WithTag from "../../../components/inputFields/WithTag";

const Claim = () => {
  return (
    <>
      <Col className="mt-2">
        <div className="shadowed-box card-main-content">
          <div className="card-title mb-3">
            <h3>CLAIM</h3>
          </div>
          <div className="mt-4">
            <WithTag
              label={"Amount"}
              placeholder={"0.0"}
              disabled={true}
              tag={
                <div className="d-flex align-items-center gap-1">
                  <div className="lamp_tag eth_lamp"></div>AIPX
                </div>
              }
            />
            <div className="d-flex justify-content-between align-items-center mt-2">
              <span className="balance">Unclaimable Rewards : 105.12 AIPX</span>
              <div className="d-flex gap-2">
                <PlButton type={"percentageButton"} label={"25%"} />
                <PlButton type={"percentageButton"} label={"50%"} />
                <PlButton type={"percentageButton"} label={"75%"} />
                <PlButton type={"percentageButton"} label={"100%"} />
              </div>
            </div>
          </div>

          <div className="modal-confirm-btn mt-4">
            <PlButton label={"Claim"} />
          </div>
        </div>
      </Col>
    </>
  );
};

export default Claim;
