import twitter from "../assets/images/twitter.svg";
import discord from "../assets/images/discord.svg";
import telegram from "../assets/images/telegram.svg";
import footerLogo from "../assets/images/footer-logo.svg";

const Footer = () => {
  return (
    <div className="footer">
      <div className="d-flex align-items-center justify-content-center">
        <div className="d-flex align-items-start justify-content-between w-100">
          <div>
            <img src={footerLogo} alt="" className="footer-logo" />
            <p className="footer-desc">All Rights Reserved</p>
          </div>
          <div className="d-flex gap-4 pt-3">
            <img src={twitter} alt="" className="footer-img" />
            <img src={discord} alt="" className="footer-img" />
            <img src={telegram} alt="" className="footer-img" />
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-center mt-2">
        <span className="text-center footer-meta">
          Built in collaboration with 0xytocin Labs{" "}
          <a href="https://0xytocin.com" target={"_blank"}>
            (https://0xytocin.com)
          </a>{" "}
          - a top web3 dev team. Contact: <a href="mailto:nash@0xytocin.com">nash@0xytocin.com.</a>
        </span>
      </div>
    </div>
  );
};

export default Footer;