import { toast } from "react-toastify";
import failImg from "../../assets/images/failed-icon.svg";
import { toastOptions } from "./ToastOptions";

export const handleError = (e) => {
  console.log("e.message--", e.message);
  console.log("e.code--", e.code);
  toast.dismiss("waiting");
  toast.dismiss("loading");
  toast.error(
    <div className="d-flex align-items-center justify-content-center gap-2 flex-column">
      <p className="toast_heading">
        <img src={failImg} alt="fail" />
        Failed to send transaction
      </p>
      <p className="toast_message">
        {e.code === "INSUFFICIENT_FUNDS"
          ? "Reason: Insufficient funds"
          : e.code === "ACTION_REJECTED"
            ? "Reason: Action rejected"
            : e.code === "UNPREDICTABLE_GAS_LIMIT"
              ? "Reason: Unpredictable gas limit"
              : e.code === "CALL_EXCEPTION"
                ? "Reason: RPC error"
                : " "}
      </p>
    </div>,
    { ...toastOptions, type: "error", style: { backgroundColor: "#252927", color: "#FF0000" } }
  );
};
