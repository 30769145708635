import PlButton from "../../components/buttons/Button";

import img1 from "./assets/01.svg";
import img2 from "./assets/02.svg";
import img3 from "./assets/03.svg";
import wallet from "./assets/connect-wallet.svg";
import download from "./assets/select-asset.svg";
import openTrade from "./assets/open-trade.svg";
import aipx from "./assets/home-ellipse.svg";

const Page = ({ handleGetStarted }) => {
  const cardDetails = [
    {
      title: "Maximize Your Strategy",
      desc: "You can control your exposure using only 1/30th of the collateral through our exceptional perpetual AMM, which offers minimal slippage and low fees.",
    },
    {
      title: "Leveraged Trading",
      desc: "A proven, cutting edge liquidity solution for BTC, ETH, ARB, MATIC, and stablecoins.",
    },
    {
      title: "Programmable Risk",
      desc: "With aiPX, liquidity providers can opt for an inventive credit exposure framework that allows them to define and manage their own risks.",
    },
    {
      title: "Complete Transparency",
      desc: "No custody, no accounts. Level is designed to be permissionless. Fully transparent and open source smart contracts.",
    },
    {
      title: "Real Yield",
      desc: "Income is natively generated in the transacted cryptocurrency through trading and lending revenue.",
    },
  ];


  return (
    <>
      <img src={aipx} alt="" className="aipx_image" />
      <div className="welcome-section">
        <h1 className="welcome-heading">Customised risk & on-demand liquidity. </h1>
        <p className="welcome-desc">
          A Decentralized Perpetual Exchange with Functional Risk Management and Innovative Liquidity Solutions on your
          favorite chain.
        </p>
        <PlButton label={"Trade Now"} className={"w-trade-now"} onClick={handleGetStarted} />
      </div>

      <div className="position-relative d-flex flex-column align-items-center justify-content-start">
        <div className="start-details-div">
          <h1 className="welcome-heading how-it-works">How it works</h1>
          <p className="welcome-desc text-center mb-0">
            aiPX employs smart contracts on the blockchain to facilitate trading. Our platform enables traders to
            deposit their assets into a smart contract, eliminating the need for intermediaries and allowing for direct
            trading. With aiPX, traders can increase their positions by up to 30x, enabling them to execute larger
            trades with less capital.
          </p>
          <div className="start-steps-div">
            <div className="start-steps-text">
              <h1 className="welcome-heading easy-start">Getting started is easy</h1>
              <p className="welcome-desc sign-in-details">
                Simply connect your wallet to our platform.
                <br />
                Once your wallet is connected, you can trade,
                <br />
                stake and earn in no time.
              </p>
              <PlButton label={"Get Started"} className={"w-get-started"} onClick={handleGetStarted} />
            </div>
            <div className="start-steps-numbers">
              <div className="all-steps">
                <img src={img1} alt="img" />
                <div className="about-step">
                  <img src={wallet} alt="img" />
                  <p className="welcome-desc mb-0 fw-600">Connect your wallet.</p>
                </div>
              </div>
              <div className="all-steps">
                <img src={img2} alt="img" />
                <div className="about-step second-step">
                  <img src={download} alt="img" />
                  <p className="welcome-desc mb-0 fw-600">Select an asset & collateral on the Trade tab.</p>
                </div>
              </div>
              <div className="all-steps">
                <img src={img3} alt="img" />
                <div className="about-step">
                  <img src={openTrade} alt="img" />
                  <p className="welcome-desc mb-0 fw-600">Open a trade.</p>
                </div>
              </div>
              {/*<PlButton label={"Get Started"} className={"w-get-started sm"} onClick={handleGetStarted} />*/}
            </div>
          </div>
        </div>
      </div>
      <div className="join-pp-aipx">
        <p className="welcome-desc mb-0">Join AIPX today and start trading on the cutting edge.</p>
        <div className="d-flex justify-content-center">
          <PlButton label={"Trade Now"} className={"absolute-get-started"}  onClick={handleGetStarted}/>
        </div>
      </div>
      <div className="cards-div">
        {cardDetails.map((item, i) => (
          <div key={i} className="detail-card d-flex flex-column">
            <p className="card-title">{item.title}</p>
            <p className="card-desc">{item.desc}</p>
          </div>
        ))}
      </div>
    </>
  );
};

export default Page;